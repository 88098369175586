import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faRectangleList } from '@fortawesome/free-solid-svg-icons';
import "../common/SideMenuBar.css"

export const UserDashboardSidebar = () => {
    const location = useLocation();
    const [selected, setSelected] = useState(location.pathname);

    useEffect(() => {
        setSelected(location.pathname);
    }, [location.pathname]);

    return (
        <div className="dashboard-sidebar">
            <ul className="nav-links">
                <li className={selected === '/user-dashboard/portfolio' ? 'selected' : ''}>
                    <Link to="/user-dashboard/portfolio">
                        <FontAwesomeIcon icon={faRectangleList} className="dashboard-sidebar-li-icon"/>
                        <span className="icon-text">Portfolio</span>
                    </Link>
                </li>
                <li className={selected === '/user-dashboard/metrics' ? 'selected' : ''}>
                    <Link to="/user-dashboard/metrics">
                        <FontAwesomeIcon icon={faChartLine} className="dashboard-sidebar-li-icon"/>
                        <span className="icon-text">Metrics</span>
                    </Link>
                </li>
            </ul>
        </div>
    );
};