import React, { useState, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { Calendar } from 'lucide-react';
import 'react-datepicker/dist/react-datepicker.css';
import './DateTimePicker.css';

const formatDate = (date) => {
    if (!date) return '';
    return date.toLocaleString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    });
};

export const DateTimePicker = forwardRef(({ selectedDate, onChange }, ref) => {
    const [inputValue, setInputValue] = useState(selectedDate ? formatDate(selectedDate) : '');

    const handleDateChange = (date) => {
        onChange(date);
        setInputValue(formatDate(date));
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        const parsedDate = new Date(e.target.value);
        if (!isNaN(parsedDate.getTime())) {
            onChange(parsedDate);
        }
    };

    const handleApply = () => {
        if (selectedDate) {
            setInputValue(formatDate(selectedDate));
        }
    };

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className="custom-input" onClick={onClick} ref={ref}>
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="mm/dd/yyyy HH:MM:SS"
                className="date-input"
            />
            <Calendar className="calendar-icon" />
        </div>
    ));

    return (
        <div className="datetime-picker-container">
            <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                customInput={<CustomInput />}
                showTimeSelect
                timeFormat="HH:mm:ss"
                timeIntervals={15}
                dateFormat="MM/dd/yyyy HH:mm:ss"
                popperClassName="custom-popper"
                renderCustomHeader={({
                                         date,
                                         changeYear,
                                         changeMonth,
                                         decreaseMonth,
                                         increaseMonth,
                                         prevMonthButtonDisabled,
                                         nextMonthButtonDisabled,
                                     }) => (
                    <div className="custom-header">
                        <button className="custom-left-btn" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                        </button>
                        <select
                            className="custom-select"
                            value={date.getFullYear()}
                            onChange={({ target: { value } }) => changeYear(Number(value))}
                        >
                            {Array.from({ length: 20 }, (_, i) => new Date().getFullYear() - 10 + i).map(
                                (year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                )
                            )}
                        </select>
                        <select
                            className="custom-select"
                            value={date.getMonth()}
                            onChange={({ target: { value } }) => changeMonth(Number(value))}
                        >
                            {[
                                "January",
                                "February",
                                "March",
                                "April",
                                "May",
                                "June",
                                "July",
                                "August",
                                "September",
                                "October",
                                "November",
                                "December",
                            ].map((month, index) => (
                                <option key={month} value={index}>
                                    {month}
                                </option>
                            ))}
                        </select>
                        <button className="custom-right-btn" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                        </button>
                    </div>
                )}
            >
                <div className="apply-button-container">
                    <button className="apply-button" onClick={handleApply}>Apply</button>
                </div>
            </DatePicker>
        </div>
    );
});