import React from 'react';
import {Route, Routes} from "react-router-dom";
import {UserGameAccessMgmt} from "./user-mgmt/UserGameAccessMgmt";
import {GameManagement} from "./game-mgmt/GameManagement";

export const SettingsDashboardContent = () => {

    return (
        <Routes>
            <Route path="user-mgmt" element={<UserGameAccessMgmt />} />
            <Route path="game-mgmt" element={<GameManagement />} />
            <Route path="org-mgmt" element={<UserGameAccessMgmt />} />
        </Routes>
    );
};