import React from 'react';
import {renderDataAsTable} from "../../../common/utils/CommonUtil";
import './Statistics.css';

const statisticsData = [
    { title: 'Games', details: ['Total Games: 150', 'Managed Games: 42'], onClick: () => alert('Games details clicked'), icon: '🎮' },
    { title: 'Regions', details: ['Targeted Regions: 35', 'Recommended Regions: 15'], onClick: () => alert('Regions details clicked'), icon: '🌍' },
    { title: 'Revenues', details: ['Current Revenues: $1,234,567 (+8.5%)', 'Targetable Revenues: $5,678,901 (+12%)'], onClick: () => alert('Revenues details clicked'), icon: '💵' },
    { title: 'Targetable Regions', details: ['India, Middle East, Mexico', 'Germany, Russia'], onClick: () => alert('Targetable Regions details clicked'), icon: '📍' }
];

const overallKpiData = [
    { title: 'Installs', details: ['1,500,000 (+7%)'], onClick: () => alert('Installs KPI details clicked'), icon: '📈' },
    { title: 'Retention', details: ['35% (+2%)'], onClick: () => alert('Retention KPI details clicked'), icon: '🔄' },
    { title: 'Monetization', details: ['$0.25 ARPDAU (+$0.03)'], onClick: () => alert('Monetization KPI details clicked'), icon: '💰' }
];

const managedRegionKpiData = [
    { title: 'Installs', details: ['500,000 (+10%)'], onClick: () => alert('Managed Regions Installs KPI details clicked'), icon: '📈' },
    { title: 'Retention', details: ['40% (+3%)'], onClick: () => alert('Managed Regions Retention KPI details clicked'), icon: '🔄' },
    { title: 'Monetization', details: ['$0.30 ARPDAU (+$0.05)'], onClick: () => alert('Managed Regions Monetization KPI details clicked'), icon: '💰' }
];

export const Statistics = () => (
    <section className="statistics-section">
        <h3>Statistics</h3>
        {renderDataAsTable(statisticsData, 4)}
        <div className="kpi-section">
            <h3>Overall KPIs</h3>
            {renderDataAsTable(overallKpiData, 3)}
        </div>
        <div className="kpi-section">
            <h3>Managed Regions KPIs</h3>
            {renderDataAsTable(managedRegionKpiData, 3)}
        </div>
    </section>
);