import React, { useState } from 'react';
import './OperatorToggleSwitch.css';

const OperatorToggleSwitch = ({ onToggle }) => {
    const [selectedOperator, setSelectedOperator] = useState('AND');

    const handleToggle = () => {
        const newOperator = selectedOperator === 'AND' ? 'OR' : 'AND';
        setSelectedOperator(newOperator);
        if (onToggle) {
            onToggle({ target: { value: newOperator } });
        }
    };

    return (
        <div className="operator-toggle-switch">
            <span
                className={`operator-toggle-option ${selectedOperator === 'AND' ? 'active' : ''}`}
                onClick={handleToggle}
            >
                AND
            </span>
            <span
                className={`operator-toggle-option ${selectedOperator === 'OR' ? 'active' : ''}`}
                onClick={handleToggle}
            >
                OR
            </span>
        </div>
    );
};

export default OperatorToggleSwitch;