import React from 'react';
import { ABTestForm } from './ABTestForm';

export const CreateABTest = () => {
    const initialFormData = {
        name: '',
        featureId: '',
        minClientVersion: '',
        maxClientVersion: '',
        segmentId: '',
        iosEnabled: false,
        androidEnabled: false,
        platforms: [],
        contentFormat: 'JSON',
        contentLocation: 'INLINE',
        goalMetric: '',
        variants: [
            { name: 'Control Group', percentage: 100, value: '' }
        ],
        startDate: '',
        endDate: '',
        active: false
    };

    return <ABTestForm initialFormData={initialFormData} />;
};