import React from 'react';
import { DashboardHeader } from '../DashboardHeader';
import { DashboardFooter } from '../DashboardFooter';
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {GameDashboardSidebar} from "./GameDashboardSidebar";
import {GameDashboardContent} from "./GameDashboardContent";
import './GameDashboard.css';

export const GameDashboard = () => {
    const { gameId, regionId } = useParams();
    const games = useSelector(state => state.userGames);
    const game = games.find(game => game.gameId === gameId && game.regionId === regionId);

    if (!game) {
        return <h2>Game not found</h2>;
    }

    return (
        <>
            <DashboardHeader gameId={gameId} regionId={regionId}/>
            <div>
                <GameDashboardSidebar gameId={gameId} regionId={regionId}/>
                <div className="content">
                    <GameDashboardContent gameId={gameId} regionId={regionId}/>
                </div>
            </div>
            <DashboardFooter/>
        </>
    );
};