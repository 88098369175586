export const conditionToString = (condition) => {
    if (!condition) return '';

    if (condition.conditions) {
        return `(${condition.conditions.filter(Boolean).map(conditionToString).join(` ${condition.operator} `)})`;
    }
    return `${condition.field || ''} ${condition.operator || ''} ${condition.value || ''}`;
};

export const updateExpression = (expression, path, updater) => {
    const newExpression = JSON.parse(JSON.stringify(expression));
    let current = newExpression;
    for (let i = 0; i < path.length - 1; i++) {
        if ('conditions' in current) {
            current = current.conditions[path[i]];
        }
    }
    if (path.length > 0 && 'conditions' in current) {
        current.conditions[path[path.length - 1]] = updater(current.conditions[path[path.length - 1]]);
    } else {
        Object.assign(current, updater(current));
    }
    return newExpression;
};

export const parseExpressionString = (expressionString) => {
    const parseCondition = (conditionString) => {
        const match = conditionString.match(/(\w+)\s*([=!<>]+)\s*(.+)/);
        if (match) {
            return {
                field: match[1],
                operator: match[2],
                value: match[3].replace(/['"]/g, '')
            };
        }
        return null;
    };

    const parseGroup = (groupString) => {
        const operator = groupString.includes(' AND ') ? 'AND' : 'OR';
        const conditions = groupString
            .replace(/^\(|\)$/g, '')
            .split(` ${operator} `)
            .map(part => {
                if (part.startsWith('(')) {
                    return parseGroup(part);
                } else {
                    return parseCondition(part);
                }
            });

        return { operator, conditions };
    };

    return parseGroup(expressionString);
};