import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { UserAuth } from "../../auth/AuthContext";
import { CountryCodes } from "../../common/country-selector/CountryCodes";
import { CountrySelector } from "../../common/country-selector/CountrySelector";
import { ModalButtons } from "../../common/modal/ModalButtons";
import "../../common/GeoPlayModal.css";
import {config} from "../../../config/Config";

Modal.setAppElement('#root');

export const UpdateGameModal = ({ isOpen, onRequestClose, game }) => {
    const { user } = UserAuth();
    const [gameName, setGameName] = useState(game.gameName);
    const [gameDescription, setGameDescription] = useState(game.gameDescription);
    const [gameIconUrl, setGameIconUrl] = useState(game.gameIconUrl);
    const [managedRegions] = useState(game.managedRegions);
    const [gameOwner, setGameOwner] = useState(game.gameOwner);
    const [gameTeamEmail, setGameTeamEmail] = useState(game.gameTeamEmail);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [error, setError] = useState(null);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    useEffect(() => {
        setSelectedCountries(managedRegions.map(region => {
            const country = CountryCodes.find(country => country.code === region);
            return { code: region, name: country ? country.name : region };
        }));
    }, [managedRegions]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formBody = {
            gameName,
            gameDescription,
            gameIconUrl,
            managedRegions: selectedCountries.map(country => country.code),
            gameOwner,
            gameTeamEmail
        };

        try {
            const url = `${config.serverBaseUrl}/v1/web/game-mgmt/${game.gameClientId}`;
            const response = await axios({
                method: 'put',
                url: url,
                data: formBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`
                },
            });
            console.log('Game has been updated successfully:', response.data);
            setShowSuccessPopup(true);
        } catch (error) {
            console.error('Failed to update the Game:', error);
            setError(error.message);
        }
    };

    useEffect(() => {
        if (showSuccessPopup) {
            const timer = setTimeout(() => {
                setShowSuccessPopup(false);
                onRequestClose();
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [showSuccessPopup, onRequestClose]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Update Game"
            className="modal"
            overlayClassName="modal-overlay"
        >
            <button className="close-button" onClick={onRequestClose}><FontAwesomeIcon icon={faXmark} /></button>
            <h2>Update Game</h2>
            <form className="modal-form" onSubmit={handleSubmit}>
                <div>
                    <label>Game Name<span className="asterisk">*</span></label>
                    <input type="text" id="gameName" value={gameName} onChange={(e) => setGameName(e.target.value)} required />
                </div>
                <div>
                    <label>Game Description</label>
                    <textarea id="gameDescription" value={gameDescription} onChange={(e) => setGameDescription(e.target.value)} required />
                </div>
                <div>
                    <label>Game Icon URL<span className="asterisk">*</span></label>
                    <input type="text" id="gameIconUrl" value={gameIconUrl} onChange={(e) => setGameIconUrl(e.target.value)} required />
                </div>
                <div>
                    <label>Managed Regions</label>
                    <CountrySelector selectedCountries={selectedCountries} setSelectedCountries={setSelectedCountries} />
                </div>
                <div>
                    <label>Game Owner<span className="asterisk">*</span></label>
                    <input type="text" id="gameOwner" value={gameOwner} onChange={(e) => setGameOwner(e.target.value)} required />
                </div>
                <div>
                    <label>Team Email Address<span className="asterisk">*</span></label>
                    <input type="email" id="gameTeamEmail" value={gameTeamEmail} onChange={(e) => setGameTeamEmail(e.target.value)} required />
                </div>
                {error && <div className="error-message">{error}</div>}
                <ModalButtons onSubmit={handleSubmit} onCancel={onRequestClose} />
            </form>
            {showSuccessPopup && (
                <div className="success-popup">
                    <p>Game Updated Successfully!</p>
                    <button onClick={onRequestClose}>Ok</button>
                </div>
            )}
        </Modal>
    );
};