import React from 'react';
import PropTypes from 'prop-types';
import { dataTypes } from './DataTypes';

export const PlayerAttributeForm = ({ gameId, name, setName, dataType, setDataType, defaultValue, setDefaultValue }) => {
    return (
        <>
            <div>
                <label>Game<span className="asterisk">*</span></label>{gameId}
            </div>
            <div>
                <label>Attribute Name<span className="asterisk">*</span></label>
                <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    placeholder="Enter attribute name"
                />
            </div>
            <div>
                <label>Data Type<span className="asterisk">*</span></label>
                <select
                    id="dataType"
                    value={dataType}
                    onChange={(e) => setDataType(e.target.value)}
                    required
                >
                    {dataTypes.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.key}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label>Default Value<span className="asterisk">*</span></label>
                <input
                    type="text"
                    id="defaultValue"
                    value={defaultValue}
                    onChange={(e) => setDefaultValue(e.target.value)}
                    required
                    placeholder="Enter default value"
                />
            </div>
        </>
    );
};

PlayerAttributeForm.propTypes = {
    name: PropTypes.string.isRequired,
    setName: PropTypes.func.isRequired,
    dataType: PropTypes.string.isRequired,
    setDataType: PropTypes.func.isRequired,
    defaultValue: PropTypes.string.isRequired,
    setDefaultValue: PropTypes.func.isRequired
};