import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faXmark } from "@fortawesome/free-solid-svg-icons";
import { AlertPopup } from "../../common/popups/AlertPopup";
import { INITIAL_EXPRESSION } from './common/SegmentExpressionConstants';
import { SegmentExpressionBuilder } from "./common/SegmentExpressionBuilder";
import { conditionToString, updateExpression } from "./common/SegmentExpressionUtils";
import {UserAuth} from "../../auth/AuthContext";
import { config } from "../../../config/Config";
import axios from "axios";
import './common/SegmentExpression.css';
import './common/AmendPlayerSegment.css';
import '../../common/Content.css';

export const CreatePlayerSegment = () => {
    const { user } = UserAuth();
    const { gameId, regionId } = useParams();
    const [segmentName, setSegmentName] = useState('');
    const [alert, setAlert] = useState(null);
    const [expression, setExpression] = useState(INITIAL_EXPRESSION);
    const [expressionString, setExpressionString] = useState(' ');
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const showAlertPopup = (alert, confirmation = false) => {
        setAlert(alert);
        setIsConfirmation(confirmation);
    };

    const closeAlertPopup = () => {
        setAlert(null);
    };

    useEffect(() => {
        setExpressionString(conditionToString(expression));
    }, [expression]);

    const handleExpressionUpdate = (path, updater) => {
        setExpression(prevExpression => updateExpression(prevExpression, path, updater));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const body = {
            segmentName: segmentName,
            segmentExpression: expressionString
        };

        try {
            const url = `${config.serverBaseUrl}/v1/web/player-segment`;
            const response = await axios({
                method: "post",
                url: url,
                data: body,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`,
                    'X-Web-Game-Id': gameId,
                    'X-Web-Region-Id': 'US'
                }
            });
            if (response.data) {
                console.log("Successfully saved new Player Segment!");
                navigate(`/game/${gameId}/${regionId}/player-segment`);
            } else {
                console.error(`HTTP error! status: ${response.status}`);
            }
        } catch (err) {
            let { message, detailedMessage } = err.response?.data || {};
            message = detailedMessage ? `${message}: ${detailedMessage}` : message;
            console.error(`Server error: ${message}`);
            setError(message);
        }
    };

    function handleCancel() {
        showAlertPopup("Are you sure you want to cancel? All unsaved changes will be lost.", true);
    }

    function handleConfirmCancel() {
        closeAlertPopup();
        navigate(`/game/${gameId}/${regionId}/player-segment`);
    }

    return (
        <div className="content-element">
            <div className="content-element-header">
                <h1>Create New Player Segment</h1>
            </div>

            <div className="amend-player-segment-section">
                <form className="amend-player-segment-form" onSubmit={handleSubmit}>
                    <div>
                        <label>Game<span className="asterisk">*</span></label>{gameId}
                    </div>
                    <div>
                        <label>Segment Name<span className="asterisk">*</span></label>
                        <input type="text" id="segmentName" placeholder="all players" value={segmentName} onChange={(e) => setSegmentName(e.target.value)} required />
                    </div>
                    <div className="segment-condition">
                        <label>Segment Condition<span className="asterisk">*</span></label>
                        <h6>{expressionString}</h6>
                        <SegmentExpressionBuilder
                            gameId={gameId}
                            expression={expression}
                            updateExpression={handleExpressionUpdate}
                            path={[]}
                            showAlertPopup={showAlertPopup}
                        />
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    <div className="content-action-buttons">
                        <button type="submit" className="submit-button"><FontAwesomeIcon icon={faFloppyDisk} className="submit-icon"/>Submit</button>
                        <button type="button" className="cancel-button" onClick={handleCancel}><FontAwesomeIcon icon={faXmark} className='cancel-icon'/>Cancel</button>
                    </div>
                </form>
                {alert && (
                    <AlertPopup
                        message={alert}
                        onClose={closeAlertPopup}
                        onConfirm={isConfirmation ? handleConfirmCancel : undefined}
                        isConfirmation={isConfirmation}
                    />
                )}
            </div>
        </div>
    );
};