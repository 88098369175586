import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Modal from "react-modal";
import {ModalButtons} from "../../common/modal/ModalButtons";
import {UserAuth} from "../../auth/AuthContext";
import '../../common/GeoPlayModal.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {useParams} from "react-router-dom";
import {config} from "../../../config/Config";
import axios from "axios";

export const CreateClientVersionModal = ({ isOpen, onClose, onCreate }) => {
    const { user } = UserAuth();
    const [version, setVersion] = useState('');
    const { gameId } = useParams();
    const [error, setError] = useState('');
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formBody = {
            version: version
        };

        try {
            const url = `${config.serverBaseUrl}/v1/web/client-version`;
            const response = await axios({
                method: "post",
                url: url,
                data: formBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`,
                    'X-Web-Game-Id': gameId
                },
            });
            const newClientVersion = response.data.newClientVersion;
            setShowSuccessPopup(true);
            setError('');
            onCreate(newClientVersion);
        } catch (error) {
            console.error('Failed to create new Client Version:', error);
            setError(error.message);
        }
    };

    const handleClosePopup = useCallback(() => {
        setShowSuccessPopup(false);
        onClose();
    }, [onClose]);

    useEffect(() => {
        if (showSuccessPopup) {
            const timer = setTimeout(() => {
                handleClosePopup();
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [showSuccessPopup, handleClosePopup]);

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Create New Client Version"
            className="modal"
            overlayClassName="modal-overlay"
        >
            <button className="close-button" onClick={onClose}><FontAwesomeIcon icon={faXmark}/></button>
            <h2>Create New Client Version</h2>
            <form className="modal-form" onSubmit={handleSubmit}>
                <div>
                    <label>Game Id<span className="asterisk">*</span></label>
                    {gameId && gameId}
                </div>
                <div>
                    <label>Client Version<span className="asterisk">*</span></label>
                    <input type="text" placeholder="Client Version" value={version}
                           onChange={(e) => setVersion(e.target.value)} required/>
                </div>
                {error && <div className="error-message">{error}</div>}
                <ModalButtons onSubmit={handleSubmit} onCancel={onClose}/>
            </form>
            {showSuccessPopup && (
                <div className="success-popup">
                    <p>Request Submitted Successfully!</p>
                    <button onClick={handleClosePopup}>Ok</button>
                </div>
            )}
        </Modal>
    );
};

CreateClientVersionModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
};