import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import RemoteConfigImage from "../../../assets/remote-config.png";
import { UserAuth } from "../../auth/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { ActionsMenuRenderer } from "../../common/actions-menu/ActionsMenuRenderer";
import { AlertPopup } from "../../common/popups/AlertPopup";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import './ABTest.css'
import '../../common/Content.css';
import {config} from "../../../config/Config";

export const ABTest = () => {
    const { user } = UserAuth();
    const { gameId, regionId } = useParams();
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [globalRowData, setGlobalRowData] = useState([]);
    const [error, setError] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [abTestToDelete, setABTestToDelete] = useState(null);
    const remoteConfigImagePath = /** @type {string} */ (RemoteConfigImage);

    const handleUpdate = (id) => {
        navigate(`/game/${gameId}/${regionId}/ab-test/${id}/update-ab-test`);
    };

    const handleDelete = (id) => {
        setABTestToDelete(id);
        setShowAlert(true);
    };

    const onConfirmDelete = async () => {
        if (abTestToDelete) {
            try {
                const url = `${config.serverBaseUrl}/v1/web/ab-test/${abTestToDelete}`;
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`,
                    'X-Web-Game-Id': gameId,
                    'X-Web-Region-Id': regionId
                };
                await axios.delete(url, { headers });
                await fetchABTests();
            } catch (error) {
                console.error('Error deleting A/B Test:', error);
            } finally {
                setShowAlert(false);
                setABTestToDelete(null);
            }
        }
    };

    const onCloseAlert = () => {
        setShowAlert(false);
        setABTestToDelete(null);
    };

    const actionsRenderer = (params) => {
        const actions = [
            {
                icon: faPen,
                label: 'Update',
                handler: () => handleUpdate(params.data.id)
            },
            {
                icon: faTrash,
                label: 'Delete',
                handler: () => handleDelete(params.data.id)
            }
        ];
        return <ActionsMenuRenderer actions={actions} />;
    };

    const [columnDefs] = useState([
        {
            headerName: "Actions",
            cellRenderer: actionsRenderer,
            maxWidth: 100
        },
        { field: "id" },
        { field: "name" },
        { field: "featureName" },
        { field: "minClientVersion" },
        { field: "maxClientVersion" },
        { field: "segmentName" },
        { field: "platform.iosEnabled" },
        { field: "platform.androidEnabled" },
        { field: "startDate" },
        { field: "endDate" },
        { field: "active" },
        { field: "contentFormat" },
        { field: "contentLocation" },
        { field: "lastModifiedDate" },
        { field: "lastModifiedBy"}
    ]);

    const defaultColDef = useMemo(() => {
        return {
            filter: "agTextColumnFilter",
            floatingFilter: true,
        };
    }, []);

    const fetchABTests = useCallback(async () => {
        try {
            const url = `${config.serverBaseUrl}/v1/web/ab-test`;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.accessToken}`,
                'X-Web-Game-Id': gameId,
                'X-Web-Region-Id': regionId
            };
            const response = await axios.get(url, { headers });
            const abTests = response.data.abTests || [];
            setRowData(abTests);
            setError('');
        } catch (error) {
            console.error('Error fetching A/B Tests:', error);
            setError("Failed to fetch A/B Tests with error " + error.message + ", please contact support");
            setRowData([]);
        }
    }, [gameId, regionId, user.accessToken]);

    const fetchGlobalABTests = useCallback(async () => {
        try {
            const url = `${config.serverBaseUrl}/v1/web/ab-test/global`;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.accessToken}`,
                'X-Web-Game-Id': gameId
            };
            const response = await axios.get(url, { headers });
            const abTests = response.data.abTests || [];
            setGlobalRowData(abTests);
            setError('');
        } catch (error) {
            console.error('Error fetching Global A/B Tests:', error);
            setError("Failed to fetch Global A/B Tests with error " + error.message + ", please contact support");
            setGlobalRowData([]);
        }
    }, [gameId, user.accessToken]);

    useEffect(() => {
        fetchABTests().then(() => console.log("A/B Tests have been fetched successfully!"));
        fetchGlobalABTests().then(() => console.log("Global A/B Tests have been fetched successfully!"));
    }, [fetchABTests, fetchGlobalABTests]);

    function handleCreateNewABTest() {
        navigate(`/game/${gameId}/${regionId}/ab-test/new-ab-test`);
    }

    return (
        <div className="content-element">
            <div className="content-element-header">
                <h1>A/B Testing</h1>
                <button className="create-button" onClick={handleCreateNewABTest}>
                    <FontAwesomeIcon icon={faCirclePlus}/>
                    <span className="button-icon-text"/>Create New A/B Test
                </button>
            </div>
            <div className="main-content">
                {rowData.length > 0 ? (
                    <>
                        <div className="region-abtests-header">
                            <h3>{regionId}'s A/B Tests</h3>
                        </div>
                        <div className={"ag-theme-quartz"} style={{height: 600, width: '100%'}}>
                            <AgGridReact
                                rowData={rowData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                rowSelection="multiple"
                                suppressRowClickSelection={true}
                                pagination={true}
                                paginationPageSize={10}
                                paginationPageSizeSelector={[10, 25, 50]}
                            />
                        </div>
                        <div className="region-abtests-header">
                            <h3>Global A/B Tests</h3>
                        </div>
                        <div className={"ag-theme-quartz"} style={{height: 600, width: '100%'}}>
                            <AgGridReact
                                rowData={globalRowData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                rowSelection="multiple"
                                suppressRowClickSelection={true}
                                pagination={true}
                                paginationPageSize={10}
                                paginationPageSizeSelector={[10, 25, 50]}
                            />
                        </div>
                    </>
                ) : (
                    <div className="main-content-dummy-data">
                        <div className="main-content-image">
                            <img src={remoteConfigImagePath} alt="Remote Config"/>
                        </div>
                        <div className="main-content-text">
                            <h3>Welcome to A/B Testing</h3>
                            <p>Level up your game with A/B testing! Pit different versions against each other, boost player
                                engagement, and enhance your game's experience. Make data-driven decisions for epic
                                improvements. Start your first A/B test now and dominate the leaderboard!</p>
                        </div>
                    </div>
                )}
                {error && <div className="error-message">{error}</div>}
                {showAlert && (
                    <AlertPopup
                        message="Are you sure you want to delete this remote config?"
                        onClose={onCloseAlert}
                        onConfirm={onConfirmDelete}
                        isConfirmation={true}
                    />
                )}
            </div>
        </div>
    );
};