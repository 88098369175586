import { useState, useCallback } from 'react';
import {config} from "../../config/Config";
import axios from 'axios';

export const RequestHandler = () => {
    const [error, setError] = useState(null);

    const handleRequest = useCallback(async (method, url, params, body, onSuccess) => {
        try {
            url = `${config.serverBaseUrl}/${url}`;
            const queryString = new URLSearchParams(params).toString();
            const fullUrl = queryString ? `${url}?${queryString}` : url;

            const response = await axios({
                method,
                url: fullUrl,
                data: body,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            onSuccess(response);
        } catch (err) {
            let { message, detailedMessage } = err.response?.data || {};
            message = detailedMessage ? `${message}: ${detailedMessage}` : message;
            console.error(`Server error: ${message}`);
            setError(message);
        }
    }, []); // Add dependencies if any

    return { handleRequest, error, setError };
};