import React from 'react';
import {Route, Routes} from "react-router-dom";
import {Overview} from "./overview/Overview";
import {PlayerAttribute} from "./player-attribute/PlayerAttribute";
import {PlayerSegment} from "./player-segment/PlayerSegment";
import {CreatePlayerSegment} from "./player-segment/CreatePlayerSegment";
import {UpdatePlayerSegment} from "./player-segment/UpdatePlayerSegment";
import {RemoteConfig} from "./remote-config/RemoteConfig";
import {CreateRemoteConfig} from "./remote-config/CreateRemoteConfig";
import {UpdateRemoteConfig} from "./remote-config/UpdateRemoteConfig";
import {ABTest} from "./ab-test/ABTest";
import {CreateABTest} from "./ab-test/CreateABTest";
import {UpdateABTest} from "./ab-test/UpdateABTest";
import {AIInsights} from "./ai-insights/AIInsights";

export const GameDashboardContent = () => {

    return (
        <Routes>
            <Route path="overview" element={<Overview />} />
            <Route path="insights" element={<AIInsights />} />
            <Route path="player-attribute" element={<PlayerAttribute />} />
            <Route path="player-segment" element={<PlayerSegment />} />
            <Route path="player-segment/new-player-segment" element={<CreatePlayerSegment />} />
            <Route path="player-segment/:id/update-player-segment" element={<UpdatePlayerSegment />} />
            <Route path="remote-config" element={<RemoteConfig />} />
            <Route path="remote-config/new-remote-config" element={<CreateRemoteConfig />} />
            <Route path="remote-config/:id/update-remote-config" element={<UpdateRemoteConfig />} />
            <Route path="ab-test" element={<ABTest />} />
            <Route path="ab-test/new-ab-test" element={<CreateABTest />} />
            <Route path="ab-test/:id/update-ab-test" element={<UpdateABTest />} />
        </Routes>
    );
};