import React from 'react';
import { DateTimePicker } from "../../common/date-time-picker/DateTimePicker";
import { ToggleSwitch } from "../../common/toggle-switch/ToggleSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

export const BasicDetails = ({ formData, handleInputChange }) => (
    <div className="form-group">
        <label htmlFor="name">Name<span className="asterisk">*</span></label>
        <input type="text" id="name" name="name" placeholder="Enter Config name"
               value={formData.name} onChange={handleInputChange}
               required/>
    </div>
);

export const Schedule = ({ formData, handleStartDateChange, handleEndDateChange }) => (
    <>
        <div className="form-group">
            <label htmlFor="startDate">Start Date<span className="asterisk">*</span></label>
            <DateTimePicker selectedDate={formData.startDate} onChange={(date) => handleStartDateChange(date)} />
        </div>
        <div className="form-group">
            <label htmlFor="endDate">End Date<span className="asterisk">*</span></label>
            <DateTimePicker selectedDate={formData.endDate} onChange={(date) => handleEndDateChange(date)} />
        </div>
    </>
);

export const Target = ({ formData, features, versions, segments, handleInputChange, setIsFeatureModalOpen, setIsClientVersionModalOpen }) => (
    <>
        <div className="form-group">
            <label htmlFor="featureId">Feature<span className="asterisk">*</span></label>
            <div className="select-with-button">
                <select id="featureId" name="featureId" value={formData.featureId}
                        onChange={handleInputChange}
                        required>
                    <option value="" disabled>Select Feature</option>
                    {features.map(feature => (
                        <option key={feature.id} value={feature.id}>{feature.name}</option>
                    ))}
                </select>
                <button type="button" className="add-button"
                        onClick={() => setIsFeatureModalOpen(true)}><FontAwesomeIcon
                    icon={faCirclePlus} className="add-icon"/>Add Feature
                </button>
            </div>
        </div>
        <div className="form-group">
            <label htmlFor="minClientVersion">Min Client Version<span className="asterisk">*</span></label>
            <div className="select-with-button">
                <select id="minClientVersion" name="minClientVersion"
                        value={formData.minClientVersion}
                        onChange={handleInputChange} required>
                    <option value="" disabled>Select Min Client Version</option>
                    {versions.map(version => (
                        <option key={version.version} value={version.version}>{version.version}</option>
                    ))}
                </select>
                <button type="button" className="add-button"
                        onClick={() => setIsClientVersionModalOpen(true)}><FontAwesomeIcon
                    icon={faCirclePlus} className="add-icon"/>Add Client Version
                </button>
            </div>
        </div>
        <div className="form-group">
            <label htmlFor="maxClientVersion">Max Client Version<span className="asterisk">*</span></label>
            <div className="select-with-button">
                <select id="maxClientVersion" name="maxClientVersion"
                        value={formData.maxClientVersion}
                        onChange={handleInputChange} required>
                    <option value="" disabled>Select Max Client Version</option>
                    {versions.map(version => (
                        <option key={version.version} value={version.version}>{version.version}</option>
                    ))}
                </select>
            </div>
        </div>
        <div className="form-group">
            <label htmlFor="segmentId">Segment Name<span className="asterisk">*</span></label>
            <select id="segmentId" name="segmentId" value={formData.segmentId}
                    onChange={handleInputChange} required>
                <option value="" disabled>Select Segment Name</option>
                {segments.map(segment => (
                    <option key={segment.id} value={segment.id}>{segment.segmentName}</option>
                ))}
            </select>
        </div>
        <div className="form-group">
            <label>Platforms<span className="asterisk">*</span></label>
            <div className="checkbox-group">
                <input type="checkbox" name="platforms" value="all" onChange={handleInputChange}
                       checked={formData.platforms.includes('all')}/> All
                <input type="checkbox" name="iosEnabled" onChange={handleInputChange}
                       checked={formData.iosEnabled}/> iOS
                <input type="checkbox" name="androidEnabled" onChange={handleInputChange}
                       checked={formData.androidEnabled}/> Android
            </div>
        </div>
    </>
);

export const Activate = ({isActive, handleToggle}) => (
    <div className="form-group">
        <label htmlFor="active">Active<span className="asterisk">*</span></label>
        <div className="active-toggle-switch">
            <ToggleSwitch isActive={isActive} onToggle={handleToggle}/>
        </div>
    </div>
);

export const ContentFormat = ({ formData, handleInputChange, isDisabled }) => (
    <div className="form-group">
        <label htmlFor="contentFormat">Content Format<span className="asterisk">*</span></label>
        <select id="contentFormat" name="contentFormat" value={formData.contentFormat}
                onChange={handleInputChange} required disabled={isDisabled}>
            <option value="">Select Content Format</option>
            <option value="JSON">JSON</option>
            <option value="CSV">CSV</option>
            <option value="XML">XML</option>
            <option value="XLS">XLS</option>
        </select>
    </div>
);

export const ContentLocation = ({ formData, handleInputChange, isDisabled }) => (
    <div className="form-group">
        <label htmlFor="contentLocation">Content Location<span className="asterisk">*</span></label>
        <input type="text" id="contentLocation" name="contentLocation"
               placeholder="Content location"
               value={formData.contentLocation}
               onChange={handleInputChange} disabled={isDisabled} required/>
    </div>
);