import { createStore } from 'redux';

const initialState = {
    userGames: [],
    completePortfolioGames: []
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_USER_GAMES':
            return { ...state, userGames: action.payload };
        case 'SET_COMPLETE_PORTFOLIO_GAMES':
            return { ...state, completePortfolioGames: action.payload };
        case 'SET_USER_DATA_FROM_SERVER':
            return { ...state, userDataFromServer: action.payload };
        default:
            return state;
    }
}

export const ReduxStore = createStore(rootReducer);