import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from "react-redux";
import { UserAuth } from "../../../auth/AuthContext";
import { CountrySelector } from "../../../common/country-selector/CountrySelector";
import { ModalButtons } from "../../../common/modal/ModalButtons";
import { config } from "../../../../config/Config";
import axios from "axios";
import "../../../common/country-selector/CountrySelector.css";
import "../../../common/GeoPlayModal.css";
import "../../../common/ModalSuccessPopUp.css";

export const GameRegistrationForm = ({ onRequestClose }) => {
    const { user } = UserAuth();
    const userDataFromServer = useSelector(state => state.userDataFromServer);
    const [gameName, setGameName] = useState('');
    const [error, setError] = useState(null);
    const [gameClientId, setGameClientId] = useState('');
    const [gameIconUrl, setGameIconUrl] = useState('');
    const [gameDescription, setGameDescription] = useState('');
    const [gameTeamEmail, setGameTeamEmail] = useState('');
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [selectedCountries, setSelectedCountries] = useState([]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formBody = {
            orgId: userDataFromServer.orgId,
            gameName,
            gameClientId,
            gameIconUrl,
            gameDescription,
            gameOwner: userDataFromServer.userId,
            gameTeamEmail,
            managedRegions: selectedCountries.map(country => country.code),
        };

        try {
            const url = `${config.serverBaseUrl}/v1/web/game-mgmt`;
            await axios({
                method: 'post',
                url: url,
                data: formBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`
                },
            });
            setShowSuccessPopup(true);
        } catch (error) {
            console.error('Failed to register a Game:', error);
            setError(error.message);
        }
    };

    const handleClosePopup = useCallback(() => {
        setShowSuccessPopup(false);
        onRequestClose();
    }, [onRequestClose]);

    useEffect(() => {
        if (showSuccessPopup) {
            const timer = setTimeout(() => {
                handleClosePopup();
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [showSuccessPopup, handleClosePopup]);

    return (
        <>
            <form className="modal-form" onSubmit={handleSubmit}>
                <div>
                    <label>Org Id<span className="asterisk">*</span></label>
                    {userDataFromServer && userDataFromServer.orgId}
                </div>
                <div>
                    <label>Game Name<span className="asterisk">*</span></label>
                    <input type="text" id="gameName" placeholder="NFSNL" value={gameName}
                           onChange={(e) => setGameName(e.target.value)} required/>
                </div>
                <div>
                    <label>Game Client Id<span className="asterisk">*</span></label>
                    <input type="text" id="gameClientId" placeholder="NFSNL_MOBILE" value={gameClientId}
                           onChange={(e) => setGameClientId(e.target.value)} required/>
                </div>
                <div>
                    <label>Game Icon URL<span className="asterisk">*</span></label>
                    <input type="text" id="gameIconUrl" placeholder="https://s3.amazonaws.com/bucket/path/image.png"
                           value={gameIconUrl}
                           onChange={(e) => setGameIconUrl(e.target.value)} required/>
                </div>
                <div>
                    <label>Game Description</label>
                    <textarea id="gameDescription" placeholder="Game Description" value={gameDescription}
                              onChange={(e) => setGameDescription(e.target.value)} required/>
                </div>
                <div>
                    <label>Game Owner<span className="asterisk">*</span></label>
                    {userDataFromServer && userDataFromServer.userId}
                </div>
                <div>
                    <label>Team Email Address<span className="asterisk">*</span></label>
                    <input type="email" id="gameTeamEmail" placeholder="gameteam@email.com" value={gameTeamEmail}
                           onChange={(e) => setGameTeamEmail(e.target.value)} required/>
                </div>
                <CountrySelector
                    selectedCountries={selectedCountries}
                    setSelectedCountries={setSelectedCountries}
                />
                {error && <div className="error-message">{error}</div>}
                <ModalButtons onSubmit={handleSubmit} onCancel={onRequestClose} />
            </form>
            {showSuccessPopup && (
                <div className="success-popup">
                    <p>Request Submitted Successfully!</p>
                    <button onClick={handleClosePopup}>Ok</button>
                </div>
            )}
        </>
    );
};