import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGamepad, faCirclePlus, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { RegisterGameModal } from "./game-mgmt/RegisterGameModal";
import { RequestGameModal } from "./game-mgmt/RequestGameAccess";
import { Statistics } from "./statistics/Statistics";
import { UserAuth } from "../../auth/AuthContext";
import { GameInfo } from "./GameInfo";
import './Portfolio.css';

const getUniqueGames = (games) => {
    return [...new Map(games.map(game => [game.gameId, game])).values()];
};

export const Portfolio = () => {
    const { user } = UserAuth();
    const userGames = useSelector(state => state.userGames);
    const completePortfolioGames = useSelector(state => state.completePortfolioGames);
    const userDataFromServer = useSelector(state => state.userDataFromServer);
    const [selectedGame, setSelectedGame] = useState(null);

    const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
    const [isRequestModalOpen, setIsRequestModalOpen] = useState(false);

    const handleNewGameClick = () => setIsRegisterModalOpen(true);
    const handleRequestGameClick = () => setIsRequestModalOpen(true);
    const closeRegisterModal = () => setIsRegisterModalOpen(false);
    const closeRequestModal = () => setIsRequestModalOpen(false);

    useEffect(() => {
        if (userGames && userGames.length > 0) {
            setSelectedGame(userGames[0]);
        }
    }, [userGames]);

    const renderCompletePortfolioGame = (game) => (
        <div className="static-game-icon">
            <FontAwesomeIcon icon={faGamepad} />
            <p className="game-icon-text">{game.gameClientId}</p>
        </div>
    );

    const renderCompletePortfolioGames = () => {
        const numberOfColumns = 6;
        const numberOfRows = Math.ceil(completePortfolioGames.length / numberOfColumns);
        let tableRows = [];

        for (let row = 0; row < numberOfRows; row++) {
            let tableCells = [];
            for (let col = 0; col < numberOfColumns; col++) {
                const gameIndex = row * numberOfColumns + col;
                if (gameIndex < completePortfolioGames.length) {
                    const game = completePortfolioGames[gameIndex];
                    tableCells.push(
                        <td key={`cell-${gameIndex}`}>
                            {renderCompletePortfolioGame(game)}
                        </td>
                    );
                } else {
                    // Fill empty cells if there are less than 4 games in the last row
                    tableCells.push(<td key={`cell-${gameIndex}`}></td>);
                }
            }
            tableRows.push(<tr key={`row-${row}`}>{tableCells}</tr>);
        }
        return <table className="games-table">
            <tbody>{tableRows}</tbody>
        </table>;
    };

    const handleClick = (game) => {
        setSelectedGame(game);
    };

    return (
        <>
            <div className="portfolio-container">
                <div className="portfolio-header">
                    <div className="portfolio-welcome">
                        <h1>Hello, {user && user.displayName}!</h1>
                    </div>
                    <div className="action-buttons">
                        <button onClick={handleNewGameClick}>
                            <FontAwesomeIcon icon={faCirclePlus} /><span className="button-icon-text">Register a New Game</span>
                        </button>
                        <button onClick={handleRequestGameClick}>
                            <FontAwesomeIcon icon={faUnlock} /><span className="button-icon-text">Request Game Access</span>
                        </button>
                    </div>
                    <RegisterGameModal isOpen={isRegisterModalOpen} onRequestClose={closeRegisterModal} />
                    <RequestGameModal user={user} games={completePortfolioGames} isOpen={isRequestModalOpen}
                                      onRequestClose={closeRequestModal} />
                </div>

                <div className="portfolio-content">
                    <Statistics />

                    <div className="managed-games-section">
                        <div className="managed-games-header">
                            <h3>Managed Games</h3>
                        </div>
                        <div className="managed-games">
                            <div className="managed-games-names">
                                {getUniqueGames(userGames).map(uniqueGame => (
                                    <div
                                        className={`game-icon hover-effect ${selectedGame && selectedGame.gameId === uniqueGame.gameId ? 'selected' : ''}`}
                                        key={uniqueGame.gameId}
                                        onClick={() => handleClick(uniqueGame)}
                                    >
                                        <FontAwesomeIcon icon={faGamepad}/>
                                        <p className="game-icon-text">{uniqueGame.gameId}</p>
                                        <div className="game-regions">
                                            {userGames.filter(game => game.gameId === uniqueGame.gameId).map(game => (
                                                <p key={`${game.gameId}-${game.regionId}`}
                                                   className="game-region-text">{game.region}</p>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="managed-games-details">
                                {selectedGame ? (
                                    <GameInfo game={selectedGame}/>
                                ) : (
                                    <div>
                                        Click on a game to see the details.
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="complete-portfolio-games-section">
                        <div className="complete-portfolio-games-header">
                            <h3>{userDataFromServer && userDataFromServer.orgId}'s Complete Portfolio</h3>
                        </div>
                        <div className="complete-portfolio-games">
                            {completePortfolioGames && completePortfolioGames.length > 0 ? renderCompletePortfolioGames() :
                                <p>No games available</p>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};