import React from 'react';
import {SettingsDashboardSidebar} from "./SettingsDashboardSidebar";
import {DashboardHeader} from "../DashboardHeader";
import {DashboardFooter} from "../DashboardFooter";
import {SettingsDashboardContent} from "./SettingsDashboardContent";
import "./SettingsDashboard.css"

export const SettingsDashboard = () => {

    return (
        <>
            <DashboardHeader />
            <div>
                <SettingsDashboardSidebar />
                <div className="content">
                    <SettingsDashboardContent />
                </div>
            </div>
            <DashboardFooter/>
        </>
    );
};