import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from '../../ui/card';

const InsightSection = ({ title, content, isExpanded, onToggle }) => {
    const renderContent = (contentData) => {
        if (!contentData) return null;

        const textContent = typeof contentData === 'string' ?
            contentData : JSON.stringify(contentData, null, 2);

        let currentSection = '';
        const sections = [];

        textContent.split('\n').forEach((line) => {
            const trimmedLine = line.trim();
            if (!trimmedLine) return;

            if (trimmedLine.startsWith('[') && trimmedLine.endsWith(']')) {
                // This is a new section header
                currentSection = trimmedLine;
                sections.push({ header: currentSection, items: [] });
            } else {
                // This is a content line
                if (sections.length === 0) {
                    sections.push({ header: '', items: [] });
                }
                sections[sections.length - 1].items.push(trimmedLine);
            }
        });

        return sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="mb-6 last:mb-0">
                {section.header && (
                    <div className="bg-white rounded-t-lg border border-gray-200 p-3">
                        <h4 className="font-semibold text-gray-900">
                            {section.header}
                        </h4>
                    </div>
                )}
                <div className={`bg-white ${section.header ? 'rounded-b-lg border-x border-b' : 'rounded-lg border'} border-gray-200`}>
                    {section.items.map((item, itemIndex) => {
                        const isSubPoint = item.startsWith('*') || item.startsWith('-');
                        const itemContent = isSubPoint ? item.substring(1).trim() : item;

                        return (
                            <div
                                key={itemIndex}
                                className={`p-3 border-b last:border-b-0 border-gray-100
                                          ${isSubPoint ? 'ml-4' : ''} flex gap-3 items-start`}
                            >
                                <span className="text-gray-400 mt-1 flex-shrink-0">
                                    {isSubPoint ? '○' : '•'}
                                </span>
                                <span className="text-gray-700">
                                    {itemContent}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        ));
    };

    const getCategoryStyles = (title) => {
        const category = title.toLowerCase();
        if (category.includes('monetization')) {
            return {
                border: 'border-l-amber-500',
                hover: 'hover:bg-amber-50'
            };
        }
        if (category.includes('acquisition')) {
            return {
                border: 'border-l-blue-500',
                hover: 'hover:bg-blue-50'
            };
        }
        if (category.includes('retention')) {
            return {
                border: 'border-l-green-500',
                hover: 'hover:bg-green-50'
            };
        }
        if (category.includes('engagement')) {
            return {
                border: 'border-l-purple-500',
                hover: 'hover:bg-purple-50'
            };
        }
        return {
            border: 'border-l-gray-300',
            hover: 'hover:bg-gray-50'
        };
    };

    const styles = getCategoryStyles(title);

    return (
        <Card className={`mb-4 border-l-[6px] ${styles.border} shadow-sm`}>
            <button
                onClick={() => onToggle(title)}
                className={`w-full transition-colors ${styles.hover}`}
            >
                <CardHeader className="flex flex-row items-center justify-between py-3">
                    <CardTitle className="text-lg font-medium">
                        {title}
                    </CardTitle>
                    {isExpanded ?
                        <ChevronUp className="w-5 h-5 text-gray-500" /> :
                        <ChevronDown className="w-5 h-5 text-gray-500" />
                    }
                </CardHeader>
            </button>

            {isExpanded && (
                <CardContent className="pt-4 pb-6">
                    <div className="space-y-4">
                        {renderContent(content)}
                    </div>
                </CardContent>
            )}
        </Card>
    );
};

export const MetricInsights = ({ metricsData = {} }) => {
    const [expandedSection, setExpandedSection] = useState(null);

    if (!metricsData || Object.keys(metricsData).length === 0) {
        return null;
    }

    const handleToggle = (sectionTitle) => {
        setExpandedSection(expandedSection === sectionTitle ? null : sectionTitle);
    };

    return (
        <div className="p-6 max-w-4xl mx-auto">
            {Object.entries(metricsData).map(([fileName, content]) => {
                if (!fileName.includes('insights')) return null;

                const formattedTitle = fileName
                    .replace('insights/', '')
                    .replace('_insights.json', '')
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');

                return (
                    <InsightSection
                        key={fileName}
                        title={formattedTitle}
                        content={content}
                        isExpanded={expandedSection === formattedTitle}
                        onToggle={handleToggle}
                    />
                );
            })}
        </div>
    );
};