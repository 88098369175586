// ToggleSwitch.js
import React from 'react';
import './ToggleSwtich.css'

export const ToggleSwitch = ({ isActive, onToggle }) => {
    return (
        <div className="toggle-switch" onClick={onToggle}>
            <div className={`switch ${isActive ? 'active' : 'inactive'}`}>
                <div className="switch-circle"></div>
            </div>
        </div>
    );
};