import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { config } from "../../../config/Config";
import { UserAuth } from "../../auth/AuthContext";
import { AlertCircle } from 'lucide-react';

const chartConfigs = {
    'game_new_users.csv': { xAxis: 'Date', yAxis: 'New Users', color: 'rgb(130, 202, 157)' },
    'game_daily_active_users.csv': { xAxis: 'Date', yAxis: 'Daily Active Users', color: 'rgb(136, 132, 216)' },
    'game_returning_users.csv': { xAxis: 'Date', yAxis: 'Returning Users', color: 'rgb(255, 99, 132)' },
    'game_revenues.csv': { xAxis: 'Date', yAxis: 'Revenue', color: 'rgb(54, 162, 235)' },
    'game_session_count.csv': { xAxis: 'Date', yAxis: 'Session Count', color: 'rgb(255, 206, 86)' },
    'game_store_transactions.csv': { xAxis: 'Date', yAxis: 'Store Transactions', color: 'rgb(75, 192, 192)' },
    'game_error_events.csv': { xAxis: 'Date', yAxis: 'Error Events', color: 'rgb(153, 102, 255)' }
};

export const GameCharts = ({ gameId, fileNames }) => {
    const { user } = UserAuth();
    const [chartData, setChartData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${config.serverBaseUrl}/v1/web/gp-storage/batch-download`;
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`,
                    'X-Web-Game-Id': gameId
                };
                const body = JSON.stringify({
                    bucketName: 'lng-geo-play',
                    fileNames: fileNames
                });
                const response = await fetch(url, {
                    method: 'POST',
                    headers: headers,
                    body: body
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                console.log(data);
                setChartData(data);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData().then(() => console.log("Data fetched successfully"));
    }, [gameId, user.accessToken, fileNames]);

    const renderChart = (fileName, data) => {
        const config = chartConfigs[fileName] || { xAxis: 'datetime', yAxis: 'value', color: '#8884d8' };

        if (!data || data.length === 0) {
            return (
                <div className="no-data-container">
                    <AlertCircle className="no-data-icon" />
                    <span className="no-data-text">No data available</span>
                </div>
            );
        }

        return (
            <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={config.xAxis} />
                    <YAxis dataKey={config.yAxis} />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey={config.yAxis} stroke={config.color} />
                </LineChart>
            </ResponsiveContainer>
        );
    };

    return (
        <div className="multi-chart-container">
            <div className="overview-element-header">
                <h2>Metrics</h2>
            </div>
            {fileNames.map((fileName) => (
                <div key={fileName} className="chart-container">
                    <h3>{fileName.replace('.csv', '').replace(/_/g, ' ')}</h3>
                    {renderChart(fileName, chartData[fileName])}
                </div>
            ))}
        </div>
    );
};