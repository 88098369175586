export const dataTypes = [
    { key: 'String', value: 'java.lang.String' },
    { key: 'Integer', value: 'java.lang.Integer' },
    { key: 'Float', value: 'java.lang.Float' },
    { key: 'Boolean', value: 'java.lang.Boolean' },
    { key: 'Date', value: 'java.util.Date' }
];

export const getDataTypeValue = (key) => {
    const dataType = dataTypes.find(dt => dt.key === key);
    return dataType ? dataType.value : '';
};