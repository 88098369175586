import React from 'react';
import {Provider} from "react-redux";
import {Header} from './components/home/Header';
import {Hero} from './components/home/Hero';
import {InfoSection} from './components/home/InfoSection';
import {Route, Routes} from "react-router-dom";
import {SignIn} from "./components/auth/SignIn";
import {SignUp} from "./components/auth/SignUp";
import {ProtectedRoute} from "./components/auth/ProtectedRoute";
import {Account} from "./components/auth/Account";
import {OrgRegistration} from "./components/org-registration/OrgRegistration"
import {AuthContextProvider} from "./components/auth/AuthContext";
import {UserDashboard} from "./components/user-dashboard/UserDashboard";
import {Portfolio} from "./components/user-dashboard/portfolio/Portfolio";
import {Metrics} from "./components/user-dashboard/metrics/Metrics";
import {GameDashboard} from "./components/game-dashboard/GameDashboard";
import {PlayerAttribute} from "./components/game-dashboard/player-attribute/PlayerAttribute";
import {Overview} from "./components/game-dashboard/overview/Overview";
import {AIInsights} from "./components/game-dashboard/ai-insights/AIInsights";
import {PlayerSegment} from "./components/game-dashboard/player-segment/PlayerSegment";
import {UpdatePlayerSegment} from "./components/game-dashboard/player-segment/UpdatePlayerSegment";
import {CreatePlayerSegment} from "./components/game-dashboard/player-segment/CreatePlayerSegment";
import {RemoteConfig} from "./components/game-dashboard/remote-config/RemoteConfig";
import {CreateRemoteConfig} from "./components/game-dashboard/remote-config/CreateRemoteConfig";
import {UpdateRemoteConfig} from "./components/game-dashboard/remote-config/UpdateRemoteConfig";
import {ABTest} from "./components/game-dashboard/ab-test/ABTest";
import {CreateABTest} from "./components/game-dashboard/ab-test/CreateABTest";
import {GameInfo} from "./components/user-dashboard/portfolio/GameInfo";
import {GameInfoSection} from "./components/user-dashboard/portfolio/GameInfoSection";
import {SettingsDashboard} from "./components/settings-dashboard/SettingsDashboard";
import {UserGameAccessMgmt} from "./components/settings-dashboard/user-mgmt/UserGameAccessMgmt";
import {GameManagement} from "./components/settings-dashboard/game-mgmt/GameManagement";
import {ReduxStore} from './redux-store/ReduxStore';
import './App.css';

export const App = () => {

    return (
        <Provider store={ReduxStore}>
            <div className="App">
                <AuthContextProvider>
                    <Routes>
                        <Route path='/' element={
                            <>
                                <Header />
                                <Hero />
                                <InfoSection />
                            </>
                        }/>
                        <Route path='/signin' element={<SignIn/>}/>
                        <Route path='/signup' element={<SignUp/>}/>
                        <Route path='/register' element={<OrgRegistration/>}/>
                        <Route path='/account' element={<ProtectedRoute><Account/></ProtectedRoute>}/>
                        <Route path="/game-info-section/:gameId/*" element={<GameInfoSection />}>
                            <Route path="game-info" element={<GameInfo/>}/>
                        </Route>
                        <Route path="/user-dashboard/*" element={<UserDashboard />} >
                            <Route path="portfolio" element={<Portfolio />} />
                            <Route path="metrics" element={<Metrics />} />
                        </Route>
                        <Route path="/game/:gameId/:regionId/*" element={<GameDashboard />} >
                            <Route path="overview" element={<Overview />} />
                            <Route path="insights" element={<AIInsights />} />
                            <Route path="player-attribute" element={<PlayerAttribute />} />
                            <Route path="player-segment" element={<PlayerSegment />} />
                            <Route path="player-segment/new-player-segment" element={<CreatePlayerSegment />} />
                            <Route path="player-segment/:id/update-player-segment" element={<UpdatePlayerSegment />} />
                            <Route path="remote-config" element={<RemoteConfig />} />
                            <Route path="remote-config/new-remote-config" element={<CreateRemoteConfig />} />
                            <Route path="remote-config/:id/update-remote-config" element={<UpdateRemoteConfig />} />
                            <Route path="ab-test" element={<ABTest />} />
                            <Route path="ab-test/new-ab-test" element={<CreateABTest />} />
                            <Route path="ab-test/:id/update-ab-test" element={<UpdateRemoteConfig />} />
                        </Route>
                        <Route path="/settings/*" element={<SettingsDashboard />} >
                            <Route path="user-mgmt" element={<UserGameAccessMgmt />} />
                            <Route path="game-mgmt" element={<GameManagement />} />
                            <Route path="org-mgmt" element={<UserGameAccessMgmt />} />
                        </Route>
                    </Routes>
                </AuthContextProvider>
            </div>
        </Provider>
    );
};