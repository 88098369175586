import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import RemoteConfigImage from "../../../assets/remote-config.png";
import { UserAuth } from "../../auth/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { ActionsMenuRenderer } from "../../common/actions-menu/ActionsMenuRenderer";
import { AlertPopup } from "../../common/popups/AlertPopup";
import { AgGridReact } from "ag-grid-react";
import { config } from "../../../config/Config";
import axios from "axios";
import './RemoteConfig.css'
import '../../common/Content.css';

export const RemoteConfig = () => {
    const { user } = UserAuth();
    const { gameId, regionId } = useParams();
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [globalRowData, setGlobalRowData] = useState([]);
    const [error, setError] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [configToDelete, setConfigToDelete] = useState(null);
    const remoteConfigImagePath = /** @type {string} */ (RemoteConfigImage);

    const handleUpdate = (id) => {
        navigate(`/game/${gameId}/${regionId}/remote-config/${id}/update-remote-config`);
    };

    const handleDelete = (id) => {
        setConfigToDelete(id);
        setShowAlert(true);
    };

    const onConfirmDelete = async () => {
        if (configToDelete) {
            try {
                const url = `${config.serverBaseUrl}/v1/web/remote-config/${configToDelete}`;
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`,
                    'X-Web-Game-Id': gameId,
                    'X-Web-Region-Id': regionId
                };
                await axios.delete(url, { headers });
                await fetchRemoteConfigs();
            } catch (error) {
                console.error('Error deleting remote dataconfig:', error);
            } finally {
                setShowAlert(false);
                setConfigToDelete(null);
            }
        }
    };

    const onCloseAlert = () => {
        setShowAlert(false);
        setConfigToDelete(null);
    };

    const actionsRenderer = (params) => {
        const actions = [
            {
                icon: faPen,
                label: 'Update',
                handler: () => handleUpdate(params.data.id)
            },
            {
                icon: faTrash,
                label: 'Delete',
                handler: () => handleDelete(params.data.id)
            }
        ];
        return <ActionsMenuRenderer actions={actions} />;
    };

    const [columnDefs] = useState([
        {
            headerName: "Actions",
            cellRenderer: actionsRenderer,
            maxWidth: 100
        },
        { field: "id" },
        { field: "name" },
        { field: "featureName" },
        { field: "minClientVersion" },
        { field: "maxClientVersion" },
        { field: "segmentName" },
        { field: "platform.iosEnabled" },
        { field: "platform.androidEnabled" },
        { field: "contentFormat" },
        { field: "contentLocation" },
        { field: "startDate" },
        { field: "endDate" },
        { field: "active" },
        { field: "lastModifiedDate" },
        { field: "lastModifiedBy"}
    ]);

    const defaultColDef = useMemo(() => {
        return {
            filter: "agTextColumnFilter",
            floatingFilter: true,
        };
    }, []);

    const fetchRemoteConfigs = useCallback(async () => {
        try {
            const url = `${config.serverBaseUrl}/v1/web/remote-config`;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.accessToken}`,
                'X-Web-Game-Id': gameId,
                'X-Web-Region-Id': regionId
            };
            const response = await axios.get(url, { headers });
            const configs = response.data.configs || [];
            setRowData(configs);
            setError('');
        } catch (error) {
            console.error('Error fetching Remote Configs:', error);
            setError("Failed to fetch Remote Configs with error " + error.message + ", please contact support");
            setRowData([]);
        }
    }, [gameId, regionId, user.accessToken]);

    const fetchGlobalRemoteConfigs = useCallback(async () => {
        try {
            const url = `${config.serverBaseUrl}/v1/web/remote-config/global`;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.accessToken}`,
                'X-Web-Game-Id': gameId
            };
            const response = await axios.get(url, { headers });
            const remoteConfigs = response.data.remoteConfigs || [];
            setGlobalRowData(remoteConfigs);
            setError('');
        } catch (error) {
            console.error('Error fetching Global Remote Configs:', error);
            setError("Failed to fetch Global Remote Configs with error " + error.message + ", please contact support");
            setGlobalRowData([]);
        }
    }, [gameId, user.accessToken]);

    useEffect(() => {
        fetchRemoteConfigs().then(() => console.log("Remote Configs have been fetched successfully!"));
        fetchGlobalRemoteConfigs().then(() => console.log("Global Remote Configs have been fetched successfully!"));
    }, [fetchRemoteConfigs, fetchGlobalRemoteConfigs]);

    function handleCreateNewRemoteConfig() {
        navigate(`/game/${gameId}/${regionId}/remote-config/new-remote-config`);
    }

    return (
        <div className="content-element">
            <div className="content-element-header">
                <h1>Remote Config</h1>
                <button className="create-button" onClick={handleCreateNewRemoteConfig}>
                    <FontAwesomeIcon icon={faCirclePlus}/>
                    <span className="button-icon-text"/>Create New Remote Config
                </button>
            </div>
            <div className="main-content">
                {rowData.length > 0 ? (
                    <>
                        <div className="region-configs-header">
                            <h3>{regionId}'s Remote Configs</h3>
                        </div>
                        <div className={"ag-theme-quartz"} style={{height: 600, width: '100%'}}>
                            <AgGridReact
                                rowData={rowData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                rowSelection="multiple"
                                suppressRowClickSelection={true}
                                pagination={true}
                                paginationPageSize={10}
                                paginationPageSizeSelector={[10, 25, 50]}
                            />
                        </div>
                        <div className="region-configs-header">
                            <h3>Global Remote Configs</h3>
                        </div>
                        <div className={"ag-theme-quartz"} style={{height: 600, width: '100%'}}>
                            <AgGridReact
                                rowData={globalRowData}
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                rowSelection="multiple"
                                suppressRowClickSelection={true}
                                pagination={true}
                                paginationPageSize={10}
                                paginationPageSizeSelector={[10, 25, 50]}
                            />
                        </div>
                    </>
                ) : (
                    <div className="main-content-dummy-data">
                        <div className="main-content-image">
                            <img src={remoteConfigImagePath} alt="Remote Config"/>
                        </div>
                        <div className="main-content-text">
                            <h3>Welcome to Remote Config</h3>
                            <p>Power up your game with Remote Config! Instantly tweak gameplay, personalize experiences, and roll out new features without app updates. Adapt and respond to player feedback in real time. Dive into Remote Config now and stay ahead in the game!</p>
                        </div>
                    </div>
                )}
                {error && <div className="error-message">{error}</div>}
                {showAlert && (
                    <AlertPopup
                        message="Are you sure you want to delete this remote config?"
                        onClose={onCloseAlert}
                        onConfirm={onConfirmDelete}
                        isConfirmation={true}
                    />
                )}
            </div>
        </div>
    );
};