export const CANCEL_CONFIRMATION_MESSAGE = "Are you sure you want to cancel? All unsaved changes will be lost.";

export const handlePlatformChange = (e, formData, setFormData) => {
    const { name, value, checked } = e.target;
    let updatedPlatforms = [...formData.platforms];

    if (value === 'all') {
        if (checked) {
            updatedPlatforms = ['all', 'iOS', 'Android'];
        } else {
            updatedPlatforms = [];
        }
    } else {
        if (checked) {
            updatedPlatforms.push(value);
        } else {
            updatedPlatforms = updatedPlatforms.filter(platform => platform !== value);
        }

        if (updatedPlatforms.includes('iOS') && updatedPlatforms.includes('Android')) {
            if (!updatedPlatforms.includes('all')) {
                updatedPlatforms.push('all');
            }
        } else {
            updatedPlatforms = updatedPlatforms.filter(platform => platform !== 'all');
        }
    }

    setFormData(prevState => ({
        ...prevState,
        platforms: updatedPlatforms,
        iosEnabled: updatedPlatforms.includes('iOS'),
        androidEnabled: updatedPlatforms.includes('Android')
    }));
};

export const isValidJson = (text) => {
    try {
        JSON.parse(text);
        return true;
    } catch (e) {
        return false;
    }
};

export const validateFileType = (file, allowedFileTypes, setFileError) => {
    if (file && !allowedFileTypes.includes(file.type)) {
        setFileError('Invalid file type. Please upload a valid file.');
        return false;
    } else {
        setFileError(null);
        return true;
    }
};

export const handlePlatformCheckboxChange = (name, checked, formData, setFormData) => {
    setFormData(prevState => {
        const newIosEnabled = name === 'iosEnabled' ? checked : prevState.iosEnabled;
        const newAndroidEnabled = name === 'androidEnabled' ? checked : prevState.androidEnabled;
        const newPlatforms = [
            ...(newIosEnabled ? ['iOS'] : []),
            ...(newAndroidEnabled ? ['Android'] : [])
        ];
        if (newIosEnabled && newAndroidEnabled) {
            newPlatforms.push('all');
        }
        return {
            ...prevState,
            platforms: newPlatforms,
            iosEnabled: newIosEnabled,
            androidEnabled: newAndroidEnabled
        };
    });
};

export const handleStartDateChange = (date, setFormData) => {
    setFormData(prevState => ({
        ...prevState,
        startDate: date instanceof Date ? date : new Date(date)
    }));
};

export const handleEndDateChange = (date, setFormData) => {
    setFormData(prevState => ({
        ...prevState,
        endDate: date instanceof Date ? date : new Date(date)
    }));
};

export const handleToggle = (isActive, setIsActive, setFormData) => {
    setIsActive(!isActive);
    setFormData(prevState => ({
        ...prevState,
        active: !isActive
    }));
};

export const validateSchedule = (formData, setError) => {
    if (!formData.startDate || !formData.endDate) {
        setError("Start Date and End Date must be set");
        return false;
    }

    if (new Date(formData.endDate) <= new Date(formData.startDate)) {
        setError("End Date must be after the Start Date");
        return false;
    }

    return true;
};

export const handleCancel = (showAlertPopup) => {
    showAlertPopup(CANCEL_CONFIRMATION_MESSAGE, true);
};

export const showAlertPopup = (alert, setAlert, setIsConfirmation, confirmation = false) => {
    setAlert(alert);
    setIsConfirmation(confirmation);
};

export const closeAlertPopup = (setAlert) => {
    setAlert(null);
};