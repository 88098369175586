import React, {useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserAuth } from '../../auth/AuthContext';
import { AlertPopup } from "../../common/popups/AlertPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faFloppyDisk, faXmark, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { useABTestData } from './UseABTestData';
import { CreateGameFeatureModal } from "../game-feature/CreateGameFeatureModal";
import { CreateClientVersionModal } from "../client-version/CreateClientVersionModal";
import {BasicDetails, Schedule, Target, Activate, ContentFormat, ContentLocation} from '../common/FormFieldUtil';
import {
    isValidJson,
    handleCancel,
    handleToggle,
    showAlertPopup,
    closeAlertPopup,
    validateSchedule,
    validateFileType,
    handleEndDateChange,
    handlePlatformChange,
    handleStartDateChange,
    handlePlatformCheckboxChange
} from '../common/FormUtil';
import PropTypes from "prop-types";
import {config} from "../../../config/Config";
import axios from 'axios';
import './AmendABTest.css';
import '../../common/Content.css';

export const ABTestForm = ({ isUpdate = false, initialFormData = {} }) => {
    const { user } = UserAuth();
    const { gameId, regionId, id } = useParams();
    const [alert, setAlert] = useState(null);
    const [isConfirmation] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [setFileError] = useState(null);
    const [jsonError, setJsonError] = useState(null);
    const [features, setFeatures] = useState([]);
    const [versions, setVersions] = useState([]);
    const [segments, setSegments] = useState([]);
    const [isFeatureModalOpen, setIsFeatureModalOpen] = useState(false);
    const [isClientVersionModalOpen, setIsClientVersionModalOpen] = useState(false);
    const [isActive, setIsActive] = useState(initialFormData.active || false);
    const [variants, setVariants] = useState(initialFormData.variants || [{ name: 'Control Group', percentage: 100, value: '' }]);
    const allowedFileTypes = ['application/json', 'text/csv', 'application/xml', 'application/vnd.ms-excel'];

    const [formData, setFormData] = useState({
        ...initialFormData,
        platforms: initialFormData.platforms || [],
        startDate: initialFormData.startDate ? new Date(initialFormData.startDate) : null,
        endDate: initialFormData.endDate ? new Date(initialFormData.endDate) : null,
    });

    useABTestData(user, gameId, setFeatures, setVersions, setSegments);

    useEffect(() => {
        if (initialFormData.iosEnabled && initialFormData.androidEnabled) {
            setFormData(prevState => ({
                ...prevState,
                platforms: [...new Set([...prevState.platforms, 'all'])]
            }));
        }
    }, [initialFormData.iosEnabled, initialFormData.androidEnabled]);

    const addVariant = () => {
        if (variants.length < 10) {
            setVariants([...variants, { name: `Variant ${variants.length}`, percentage: 0, value: '' }]);
        }
    };

    const removeVariant = (index) => {
        if (index > 0) {
            setVariants(variants.filter((_, i) => i !== index));
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, files, checked } = e.target;

        if (type === 'checkbox') {
            if (name === 'platforms') {
                handlePlatformChange(e, formData, setFormData);
            } else {
                setFormData(prevState => ({
                    ...prevState,
                    [name]: checked
                }));
            }
        } else if (type === 'file') {
            const file = files[0];
            if (validateFileType(file, allowedFileTypes, setFileError)) {
                setFormData(prevState => ({
                    ...prevState,
                    content: file
                }));
            }
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }

        if (name === 'contentFormat') {
            setFormData(prevState => ({
                ...prevState,
                contentLocation: value === 'JSON' ? 'INLINE' : 'S3/CDN'
            }));
        }

        if (name === 'iosEnabled' || name === 'androidEnabled') {
            handlePlatformCheckboxChange(name, checked, formData, setFormData);
        }
    };

    const handleVariantChange = (index, field, value) => {
        const newVariants = [...variants];
        if (field === 'percentage') {
            const parsedValue = parseFloat(value);
            newVariants[index][field] = isNaN(parsedValue) ? 0 : parsedValue;
        } else {
            newVariants[index][field] = value;
        }
        setVariants(newVariants);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateSchedule(formData, setError)) {
            return;
        }

        // Calculate the sum of all variant percentages
        const totalPercentage = variants.reduce((sum, variant) => sum + parseFloat(variant.percentage), 0);

        // Check if the total percentage is 100
        if (totalPercentage !== 100) {
            setError("The sum of all variant percentages must equal 100%");
            return;
        }

        // Existing validation and submission logic
        if (new Date(formData.endDate) <= new Date(formData.startDate)) {
            setError("End Date must be after Start Date");
            return;
        }

        // Validate JSON content for each variant if content format is JSON
        if (formData.contentFormat === 'JSON') {
            for (const variant of variants) {
                if (!isValidJson(variant.value)) {
                    setJsonError(`Invalid JSON format in variant "${variant.name}". Please enter a valid JSON.`);
                    return;
                }
            }
        }

        // Reset jsonError if no errors found
        setJsonError(null);

        // Prepare the data to be sent to the server
        const submissionData = {
            ...formData,
            variants: await Promise.all(variants.map(async (variant) => {
                if (variant.value instanceof File) {
                    const formData = new FormData();
                    formData.append('file', variant.value);
                    const response = await axios.post(`${config.serverBaseUrl}/v1/web/upload`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${user.accessToken}`
                        }
                    });
                    return {
                        ...variant,
                        value: response.data.fileUrl
                    };
                } else {
                    return variant;
                }
            })),
            goalMetric: formData.goalMetric
        };

        try {
            const url = isUpdate
                ? `${config.serverBaseUrl}/v1/web/ab-test/${id}`
                : `${config.serverBaseUrl}/v1/web/ab-test`;
            const method = isUpdate ? 'put' : 'post';
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.accessToken}`,
                'X-Web-Game-Id': gameId,
                'X-Web-Region-Id': regionId
            };
            await axios[method](url, submissionData, { headers });
            navigate(`/game/${gameId}/${regionId}/ab-test`);
        } catch (error) {
            console.error(`Error ${isUpdate ? 'updating' : 'creating'} A/B Test:`, error);
            setError(`Failed to ${isUpdate ? 'update' : 'create'} A/B Test. Please try again.`);
        }
    };

    const handleConfirmCancel = () => {
        closeAlertPopup(setAlert);
        navigate(`/game/${gameId}/${regionId}/ab-test`);
    };

    const handleCreateNewFeature = (newFeature) => {
        setFeatures([...features, newFeature]);
    };

    const handleCreateNewClientVersion = (newClientVersion) => {
        setVersions([...versions, newClientVersion]);
    };

    return (
        <div className="content-element">
            <div className="content-element-header">
                <h1>{isUpdate ? 'Update' : 'Create New'} A/B Test</h1>
            </div>
            <div className="ab-test-container">
                <form className="ab-test-form" onSubmit={handleSubmit}>
                    <div className="ab-test-content">
                        <div className="content-row">
                            <div className="ab-test-content-section name-section">
                                <h3>Basic Details</h3>
                                <BasicDetails formData={formData} handleInputChange={handleInputChange}/>
                            </div>
                            <div className="remote-config-content-section schedule-section">
                                <h3>Schedule</h3>
                                <Schedule formData={formData}
                                          handleStartDateChange={(date) => handleStartDateChange(date, setFormData)}
                                          handleEndDateChange={(date) => handleEndDateChange(date, setFormData)}/>
                            </div>
                        </div>
                        <div className="content-row">
                            <div className="ab-test-content-section targeting-section">
                                <h3>Target</h3>
                                <Target formData={formData} features={features} versions={versions} segments={segments}
                                        handleInputChange={handleInputChange}
                                        setIsFeatureModalOpen={setIsFeatureModalOpen}
                                        setIsClientVersionModalOpen={setIsClientVersionModalOpen}/>
                            </div>
                            <div className="ab-test-content-section actiivate-section">
                                <h3>Activate</h3>
                                <Activate isActive={isActive}
                                          handleToggle={() => handleToggle(isActive, setIsActive, setFormData)}/>
                            </div>
                        </div>
                        <div className="content-row">
                            <div className="ab-test-content-section goal-section">
                                <h3>Goal</h3>
                                <div className="form-group">
                                    <label htmlFor="goalMetric">Goal Metric<span className="asterisk">*</span></label>
                                    <select id="goalMetric" name="goalMetric" value={formData.goalMetric}
                                            onChange={handleInputChange} required>
                                        <option value="" disabled>Select Goal Metric</option>
                                        <option value="conversion">Conversion</option>
                                        <option value="engagement">Engagement</option>
                                        <option value="retention">Retention</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="content-row">
                            <div className="ab-test-content-section content-section">
                                <h3>Content</h3>
                                <div className="form-group">
                                    <ContentFormat formData={formData} handleInputChange={handleInputChange}
                                                   isDisabled={true}/>
                                </div>
                                <div className="form-group">
                                    <ContentLocation formData={formData} handleInputChange={handleInputChange}
                                                     isDisabled={true}/>
                                </div>
                                <div className="variant-section">
                                    <h3>Variants</h3>
                                    {variants.map((variant, index) => (
                                        <div key={index} className="variant-container">
                                            <div className="variant-fields">
                                                <input
                                                    type="text"
                                                    value={variant.name}
                                                    onChange={(e) => handleVariantChange(index, 'name', e.target.value)}
                                                    disabled={index === 0}
                                                    placeholder="Variant Name"
                                                />
                                                <input
                                                    type="text"
                                                    value={`${variant.percentage}%`}
                                                    onChange={(e) => handleVariantChange(index, 'percentage', e.target.value.replace('%', '') || '0')}
                                                    min="0"
                                                    max="100"
                                                    placeholder="Percentage"
                                                />
                                                {formData.contentFormat === 'JSON' ? (
                                                    <textarea
                                                        value={variant.value}
                                                        onChange={(e) => handleVariantChange(index, 'value', e.target.value)}
                                                        placeholder="Value"
                                                    />
                                                ) : (
                                                    <input
                                                        type="file"
                                                        onChange={(e) => handleVariantChange(index, 'value', e.target.files[0])}
                                                    />
                                                )}
                                                {index > 0 && (
                                                    <button type="button" onClick={() => removeVariant(index)}
                                                            className="delete-button">
                                                        <FontAwesomeIcon icon={faTrashCan} className="delete-icon"/>
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                    <button type="button" onClick={addVariant} disabled={variants.length >= 10}
                                            className="add-button">
                                        <FontAwesomeIcon icon={faCirclePlus} className="add-icon"/>Add Variant
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    {jsonError && <div className="error-message">{jsonError}</div>}
                    <div className="content-action-buttons">
                        <button type="submit" className="submit-button">
                            <FontAwesomeIcon icon={faFloppyDisk} className="submit-icon"/>
                            {isUpdate ? 'Update' : 'Submit'}
                        </button>
                        <button type="button" className="cancel-button"
                                onClick={() => handleCancel(showAlertPopup)}>
                            <FontAwesomeIcon icon={faXmark} className='cancel-icon'/>Cancel
                        </button>
                    </div>
                </form>
                {alert && (
                    <AlertPopup
                        message={alert}
                        onClose={() => closeAlertPopup(setAlert)}
                        onConfirm={isConfirmation ? handleConfirmCancel : undefined}
                        isConfirmation={isConfirmation}
                    />
                )}
                <CreateGameFeatureModal
                    isOpen={isFeatureModalOpen}
                    onClose={() => setIsFeatureModalOpen(false)}
                    onCreate={handleCreateNewFeature}
                />
                <CreateClientVersionModal
                    isOpen={isClientVersionModalOpen}
                    onClose={() => setIsClientVersionModalOpen(false)}
                    onCreate={handleCreateNewClientVersion}
                />
            </div>
        </div>
    );
};

ABTestForm.propTypes = {
    isUpdate: PropTypes.bool,
    initialFormData: PropTypes.shape({
        active: PropTypes.bool,
        platforms: PropTypes.arrayOf(PropTypes.string),
        startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        contentFormat: PropTypes.string,
        contentLocation: PropTypes.string,
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
        iosEnabled: PropTypes.bool,
        androidEnabled: PropTypes.bool,
        variants: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            percentage: PropTypes.number,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)])
        })),
        goalMetric: PropTypes.string,
    })
};