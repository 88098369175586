// Constants
const OPERATORS = { AND: 'AND', OR: 'OR' };

const FIELD_OPTIONS = [
    { value: 'field1', label: 'Field 1' },
    { value: 'field2', label: 'Field 2' }
];

const OPERATOR_OPTIONS = [
    { value: '==', label: 'Equals' },
    { value: '!=', label: 'Not Equals' },
    { value: '>', label: 'Greater Than' },
    { value: '<', label: 'Less Than' },
    { value: '>=', label: 'Greater Than or Equals' },
    { value: '<=', label: 'Less Than or Equals' }
];

const MAX_NESTING_DEPTH = 3;
const MAX_GROUPS_PER_LEVEL = 3;

const INITIAL_CONDITION = { field: '', operator: '', value: '' };

const INITIAL_EXPRESSION = { conditions: [{ ...INITIAL_CONDITION }], operator: OPERATORS.AND };

export {OPERATORS, FIELD_OPTIONS, OPERATOR_OPTIONS, MAX_NESTING_DEPTH, MAX_GROUPS_PER_LEVEL, INITIAL_CONDITION, INITIAL_EXPRESSION};