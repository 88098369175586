import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { UserAuth } from "../../../auth/AuthContext";
import { ModalButtons } from "../../../common/modal/ModalButtons";
import { CountrySelector } from "../../../common/country-selector/CountrySelector";
import { config } from "../../../../config/Config";
import axios from "axios";
import "../../../common/GeoPlayModal.css";

export const RequestGameModal = ({ isOpen, onRequestClose }) => {
    const { user } = UserAuth();
    const completePortfolioGames = useSelector(state => state.completePortfolioGames);

    const [gameId, setGameId] = useState('');
    const [accessLevel, setAccessLevel] = useState('');
    const [error, setError] = useState('');
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [availableCountries, setAvailableCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);

    useEffect(() => {
        if (gameId) {
            const game = completePortfolioGames.find(game => game.gameClientId === gameId);
            if (game) {
                const managedRegions = game.managedRegions;
                setAvailableCountries(managedRegions);
            }
        }
    }, [gameId, completePortfolioGames]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formBody = {
            userId: user.email,
            userName: user.displayName,
            gameId: gameId,
            role: accessLevel,
            regionIds: selectedCountries.map(country => country.code),
        };

        try {
            const url = `${config.serverBaseUrl}/v1/web/user-game-access`;
            await axios({
                method: "post",
                url: url,
                data: formBody,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`
                },
            });
            setShowSuccessPopup(true);
            setError('');
        } catch (error) {
            console.error('Failed to raise a Request for Game Access:', error);
            setError(error.message);
        }
    };

    const handleClosePopup = useCallback(() => {
        setShowSuccessPopup(false);
        onRequestClose();
    }, [onRequestClose]);

    useEffect(() => {
        if (showSuccessPopup) {
            const timer = setTimeout(() => {
                handleClosePopup();
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [showSuccessPopup, handleClosePopup]);

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Request Game Access"
            className="modal"
            overlayClassName="modal-overlay"
        >
            <button className="close-button" onClick={onRequestClose}><FontAwesomeIcon icon={faXmark}/></button>
            <h2>Request Game Access</h2>
            <form className="modal-form" onSubmit={handleSubmit}>
                <div>
                    <label>User EmailId<span className="asterisk">*</span></label>
                    {user && user.email}
                </div>
                <div>
                    <label>Game Id<span className="asterisk">*</span></label>
                    <select id="gameId" value={gameId} onChange={(e) => setGameId(e.target.value)} required>
                        <option value="" disabled>Select a game</option>
                        {completePortfolioGames.map((game) => (
                            <option key={game.gameClientId} value={game.gameClientId}>{game.gameName}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Access Level<span className="asterisk">*</span></label>
                    <select id="accessLevel" value={accessLevel} onChange={(e) => setAccessLevel(e.target.value)} required>
                        <option value="" disabled>Select Access Level</option>
                        <option value="SUPER_USER">SUPER_USER</option>
                        <option value="GAME_OWNER">GAME_OWNER</option>
                        <option value="GAME_USER">GAME_USER</option>
                    </select>
                </div>
                <CountrySelector
                    incomingCountryCodes={availableCountries}
                    selectedCountries={selectedCountries}
                    setSelectedCountries={setSelectedCountries}
                />
                {error && <div className="error-message">{error}</div>}
                <ModalButtons onSubmit={handleSubmit} onCancel={onRequestClose} />
            </form>
            {showSuccessPopup && (
                <div className="success-popup">
                    <p>Request Submitted Successfully!</p>
                    <button onClick={handleClosePopup}>Ok</button>
                </div>
            )}
        </Modal>
    );
};