import React from 'react';
import './MetricCard.css';

export const MetricCard = ({ title, value, icon, backgroundColor }) => {
    return (
        <div className="metric-card" style={{ backgroundColor: backgroundColor }}>
            <div className="metric-card-icon">{icon}</div>
            <div className="metric-card-content">
                <h4>{title}</h4>
                <h5>{value}</h5>
            </div>
        </div>
    );
};