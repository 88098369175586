import { useEffect } from 'react';
import {config} from "../../../config/Config";
import axios from 'axios';

export const useRemoteConfigData = (user, gameId, setFeatures, setVersions, setSegments) => {
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [featuresResponse, versionsResponse, segmentsResponse] = await Promise.all([
                    axios.get(`${config.serverBaseUrl}/v1/web/game-feature`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${user.accessToken}`,
                            'X-Web-Game-Id': gameId
                        }
                    }),
                    axios.get(`${config.serverBaseUrl}/v1/web/client-version`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${user.accessToken}`,
                            'X-Web-Game-Id': gameId
                        }
                    }),
                    axios.get(`${config.serverBaseUrl}/v1/web/player-segment`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${user.accessToken}`,
                            'X-Web-Game-Id': gameId
                        }
                    })
                ]);

                setFeatures(featuresResponse.data.features || []);
                setVersions(versionsResponse.data.versions || []);
                setSegments(segmentsResponse.data.segments || []);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData().then(() => console.log('Data fetched successfully'));
    }, [gameId, user.accessToken, setFeatures, setVersions, setSegments]);
};