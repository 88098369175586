import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { MetricCard } from "../../common/metric-cards/MetricCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CountryCodes } from "../../common/country-selector/CountryCodes";
import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import { UserAuth } from "../../auth/AuthContext";
import { config } from "../../../config/Config";
import axios from 'axios';
import './GameInfo.css';

export const GameInfo = ({ game }) => {
    const { user } = UserAuth();
    const navigate = useNavigate();
    const [regions, setRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('');

    const gameHighLevelMetrics = [
        {
            title: 'DAU',
            value: '250K',
            icon: '👤',
        },
        {
            title: 'MTX',
            value: '$50,000',
            icon: '💰',
        },
        {
            title: 'iOS Rating',
            value: '4.8',
            icon: '⭐',
        },
        {
            title: 'Daily Installs',
            value: '30K',
            icon: '📥',
        },
    ];

    useEffect(() => {
        const fetchGameManagedRegions = async () => {
            try {
                const url = `${config.serverBaseUrl}/v1/web/game-mgmt/game-client-id/${game.gameId}`;
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`,
                }
                const response = await axios.get(url, { headers });
                const managedRegions = response.data.game.managedRegions || [];
                setRegions(managedRegions);
                const defaultRegion = managedRegions[0] || '';
                setSelectedRegion(defaultRegion);
            } catch (error) {
                console.error('Failed to fetch regions:', error);
            }
        };

        fetchGameManagedRegions().then(() => console.log('Game Managed Regions fetched successfully!'));
    }, [game.gameId, user.accessToken]);

    function renderMetrics() {
        const numberOfColumns = 6;
        const numberOfRows = Math.ceil(gameHighLevelMetrics.length / numberOfColumns);
        let tableRows = [];

        for (let row = 0; row < numberOfRows; row++) {
            let tableCells = [];
            for (let col = 0; col < numberOfColumns; col++) {
                const metricIndex = row * numberOfColumns + col;
                if (metricIndex < gameHighLevelMetrics.length) {
                    const metric = gameHighLevelMetrics[metricIndex];
                    tableCells.push(
                        <td key={`cell-${metricIndex}`}>
                            <MetricCard
                                key={metricIndex}
                                title={metric.title}
                                value={metric.value}
                                icon={metric.icon}
                                backgroundColor={metric.backgroundColor}
                            />
                        </td>
                    );
                } else {
                    tableCells.push(<td key={`cell-${metricIndex}`}></td>);
                }
            }
            tableRows.push(<tr key={`row-${row}`}>{tableCells}</tr>);
        }
        return <table className="games-table">
            <tbody>{tableRows}</tbody>
        </table>;
    }

    const handleRequestGameClick = () => {
        navigate(`/game/${game.gameId}/${selectedRegion}/overview`);
    }

    return (
        <div className="game-info-element">
            <div className="game-info-header">
                <h1>{game && game.gameId}'s Info </h1>
                <div className="manage-game">
                    <select
                        value={selectedRegion}
                        onChange={(e) => setSelectedRegion(e.target.value)}
                        className="region-selector"
                    >
                        {regions.map(regionCode => {
                            const country = CountryCodes.find(country => country.code === regionCode);
                            return (
                                <option key={regionCode} value={regionCode}>
                                    {country ? country.name : regionCode}
                                </option>
                            );
                        })}
                    </select>
                    <button className="manage-game-button" onClick={handleRequestGameClick}>
                        <FontAwesomeIcon icon={faListCheck} /><span className="button-icon-text">Manage {game && game.gameId}</span>
                    </button>
                </div>
            </div>
            <div className="game-high-level-metrics">
                {renderMetrics()}
            </div>
        </div>
    );
};