import React from 'react';
import { renderDataAsTable } from "../../common/utils/CommonUtil";
import { useParams } from "react-router-dom";
import { GameCharts } from "./GameCharts";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "../../common/utils/CommonUtil.css";
import './Overview.css';

export const Overview = () => {
    const { gameId, regionId } = useParams();
    const chartFileNames = [
        'game_new_users.csv',
        'game_daily_active_users.csv',
        'game_returning_users.csv',
        'game_revenues.csv',
        'game_session_count.csv',
        'game_store_transactions.csv',
        'game_error_events.csv'
    ];

    const getIconByTitle = (title) => {
        switch (title) {
            case 'USA':
                return <span dangerouslySetInnerHTML={{__html: '<span class="fi fi-us"></span>'}}/>;
            case 'China':
                return <span dangerouslySetInnerHTML={{__html: '<span class="fi fi-cn"></span>'}}/>;
            case 'India':
                return <span dangerouslySetInnerHTML={{__html: '<span class="fi fi-in"></span>'}}/>;
            case 'Japan':
                return <span dangerouslySetInnerHTML={{__html: '<span class="fi fi-jp"></span>'}}/>;
            default:
                return <span dangerouslySetInnerHTML={{__html: '<span class="fi fi-unknown"></span>'}}/>;
        }
    };

    const regionWiseStats = [
        {
            title: 'USA',
            details: ['Revenue: $150K', 'DAU: 1M'],
            onClick: () => alert('USA Region Clicked'),
            icon: getIconByTitle('USA')
        },
        {
            title: 'China',
            details: ['Revenue: $150K', 'DAU: 1M'],
            onClick: () => alert('China Region Clicked'),
            icon: getIconByTitle('China')
        },
        {
            title: 'India',
            details: ['Revenue: $150K (+8.5%)', 'DAU: 1M'],
            onClick: () => alert('India Region Clicked'),
            icon: getIconByTitle('India')
        },
        {
            title: 'Japan',
            details: ['Revenue: $150K', 'DAU: 1M'],
            onClick: () => alert('Japan Region Clicked'),
            icon: getIconByTitle('Japan')
        }
    ];

    return (
        <section className="overview-element">
            <div className="overview-element-header">
                <h2>{gameId} - {regionId}</h2>
            </div>
            {renderDataAsTable(regionWiseStats, 4)}

            <GameCharts
                gameId={gameId}
                fileNames={chartFileNames}
            />
        </section>
    );
}