import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { UserAuth } from '../auth/AuthContext';
import { DashboardHeader } from '../DashboardHeader';
import { DashboardFooter } from '../DashboardFooter';
import { RequestHandler } from "../common/RequestHandler";
import { UserDashboardSidebar } from "./UserDashboardSidebar";
import { UserDashboardContent } from "./UserDashboardContent";
import { config } from "../../config/Config";
import axios from "axios";
import './UserDashboard.css';

export const UserDashboard = () => {
    const { user } = UserAuth();
    const { handleRequest } = RequestHandler();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchUserGames = async () => {
            if (user) {
                const url = `${config.serverBaseUrl}/v1/web/user-game`;
                try {
                    const response = await axios(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${user.accessToken}`,
                        },
                    });
                    if (response.status !== 200) {
                        console.error(`HTTP error! status: ${response.status}`);
                        dispatch({ type: 'SET_USER_GAMES', payload: [] });
                        return;
                    }
                    const data = await response.data;
                    if (data && Array.isArray(data.games)) {
                        dispatch({ type: 'SET_USER_GAMES', payload: data.games });
                    } else {
                        console.error('Expected an array for userGames, but received:', typeof data);
                        dispatch({ type: 'SET_USER_GAMES', payload: [] });
                    }
                } catch (error) {
                    console.error('Error fetching user games:', error);
                }
            }
        };

        const fetchCompletePortfolioGames = async (orgId) => {
            if (user && orgId) {
                const url = `${config.serverBaseUrl}/v1/web/game-mgmt/orgId/${orgId}`;
                try {
                    const response = await axios(url, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${user.accessToken}`,
                        },
                    });
                    if (response.status !== 200) {
                        console.error(`HTTP error! status: ${response.status}`);
                        dispatch({ type: 'SET_COMPLETE_PORTFOLIO_GAMES', payload: [] });
                        return;
                    }
                    const data = await response.data;
                    if (data && Array.isArray(data.games)) {
                        dispatch({ type: 'SET_COMPLETE_PORTFOLIO_GAMES', payload: data.games });
                    } else {
                        console.error('Expected an array for completePortfolioGames, but received:', typeof data.games);
                        dispatch({ type: 'SET_COMPLETE_PORTFOLIO_GAMES', payload: [] });
                    }
                } catch (error) {
                    console.error('Error fetching complete portfolio games:', error);
                    dispatch({ type: 'SET_COMPLETE_PORTFOLIO_GAMES', payload: [] });
                }
            }
        };

        const fetchUserDataFromServer = async () => {
            if (user) {
                const url = `${config.serverBaseUrl}/v1/web/user`;
                try {
                    const response = await axios({
                        method: 'GET',
                        url,
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${user.accessToken}`,
                        }
                    });
                    if (response.status !== 200) {
                        console.error(`HTTP error! status: ${response.status}`);
                        dispatch({ type: 'SET_USER_DATA_FROM_SERVER', payload: [] });
                        return;
                    }
                    const data = await response.data;
                    if (data) {
                        dispatch({ type: 'SET_USER_DATA_FROM_SERVER', payload: data.user });
                        return data.user.orgId; // Return orgId from user data
                    } else {
                        console.error('Expected an array for completePortfolioGames, but received:', typeof data);
                        dispatch({ type: 'SET_USER_DATA_FROM_SERVER', payload: [] });
                    }
                } catch (error) {
                    console.error('Error fetching complete portfolio games:', error);
                    dispatch({ type: 'SET_USER_DATA_FROM_SERVER', payload: [] });
                }
            }
        };

        const initializeData = async () => {
            const orgId = await fetchUserDataFromServer();
            await fetchUserGames();
            await fetchCompletePortfolioGames(orgId);
        };

        initializeData().then(() => console.log('Data has been initialized successfully'));
    }, [dispatch, handleRequest, user]);

    return (
        <>
            <DashboardHeader/>
            <div>
                <UserDashboardSidebar/>
                <div className="content">
                    <UserDashboardContent/>
                </div>
            </div>
            <DashboardFooter/>
        </>
    );
};