import React from 'react';
import { RemoteConfigForm } from './RemoteConfigForm';

export const CreateRemoteConfig = () => {
    const initialFormData = {
        name: '',
        featureId: '',
        minClientVersion: '',
        maxClientVersion: '',
        segmentId: '',
        iosEnabled: false,
        androidEnabled: false,
        content: '',
        contentFormat: 'JSON',
        contentLocation: 'INLINE',
        startDate: '',
        endDate: '',
        active: false
    };

    return <RemoteConfigForm initialFormData={initialFormData} />;
};