import React from "react";

const renderDataAsTable = (data, columns) => {
    const rows = [];
    for (let i = 0; i < data.length; i += columns) {
        const row = data.slice(i, i + columns);
        rows.push(row);
    }

    return (
        <table className="data-table">
            <tbody>
            {rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                    {row.map((item, colIndex) => (
                        <td key={colIndex} className="data-cell" onClick={item.onClick}>
                            <div className="metric-card-icon">{item.icon}</div>
                            <div className="metric-card-content">
                                <h2>{item.title}</h2>
                                {item.details.map((detail, idx) => <h5 key={idx}>{detail}</h5>)}
                            </div>
                        </td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export { renderDataAsTable }