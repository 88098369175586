import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UserAuth } from '../../auth/AuthContext';
import { ABTestForm } from './ABTestForm';
import {config} from "../../../config/Config";
import axios from 'axios';

export const UpdateABTest = () => {
    const { user } = UserAuth();
    const { gameId, regionId, id } = useParams();
    const [initialFormData, setInitialFormData] = useState(null);

    useEffect(() => {
        const fetchABTest = async () => {
            try {
                const url = `${config.serverBaseUrl}/v1/web/ab-test/${id}`;
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`,
                    'X-Web-Game-Id': gameId,
                    'X-Web-Region-Id': regionId
                };
                const response = await axios.get(url, { headers });
                const abTest = response.data.abTest;
                setInitialFormData({
                    name: abTest.name,
                    featureId: abTest.featureId,
                    minClientVersion: abTest.minClientVersion,
                    maxClientVersion: abTest.maxClientVersion,
                    segmentId: abTest.segmentId,
                    iosEnabled: abTest.platform.iosEnabled,
                    androidEnabled: abTest.platform.androidEnabled,
                    contentFormat: abTest.contentFormat,
                    contentLocation: abTest.contentLocation,
                    startDate: abTest.startDate,
                    endDate: abTest.endDate,
                    active: abTest.active,
                    variants: abTest.variants
                });
            } catch (error) {
                console.error('Error fetching A/B Test:', error);
            }
        };

        fetchABTest().then(() => console.log('A/B Test fetched successfully'));
    }, [gameId, regionId, id, user.accessToken]);

    if (!initialFormData) {
        return <div>Loading...</div>;
    }

    return <ABTestForm isUpdate={true} initialFormData={initialFormData} />;
};