import React, {useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { SegmentCondition } from './SegmentCondition';
import { INITIAL_CONDITION, MAX_GROUPS_PER_LEVEL, MAX_NESTING_DEPTH, OPERATORS } from './SegmentExpressionConstants';
import OperatorToggleSwitch from "./OperatorToggleSwitch";

export const SegmentExpressionBuilder = ({
                                             gameId,
                                             expression,
                                             updateExpression,
                                             path,
                                             onRemove,
                                             showAlertPopup
                                         }) => {

    const [operator, setOperator] = useState('AND');

    const handleAddCondition = () => {
        updateExpression(path, expr => ({
            ...expr,
            conditions: [...expr.conditions, { ...INITIAL_CONDITION }]
        }));
    };

    const handleAddGroup = () => {
        if (path.length >= MAX_NESTING_DEPTH) {
            showAlertPopup(`Maximum nesting depth reached (Max ${MAX_NESTING_DEPTH} nesting levels allowed).`);
            return;
        }

        const groupsAtCurrentLevel = expression.conditions.filter(cond => cond.conditions).length;
        if (groupsAtCurrentLevel >= MAX_GROUPS_PER_LEVEL) {
            showAlertPopup(`Maximum number of groups reached (Max ${MAX_GROUPS_PER_LEVEL} groups allowed).`);
            return;
        }

        updateExpression(path, expr => ({
            ...expr,
            conditions: [...expr.conditions, { conditions: [{ ...INITIAL_CONDITION }], operator: OPERATORS.AND }]
        }));
    };

    const handleOperatorChange = (e) => {
        updateExpression(path, expr => ({ ...expr, operator: e.target.value }));
    };

    const removeExpression = (indexToRemove) => {
        updateExpression(path, expr => ({
            ...expr,
            conditions: expr.conditions.filter((_, index) => index !== indexToRemove)
        }));
    };

    return (
        <div className={`expression-group ${path.length > 0 ? 'nested' : ''}`}>
            <div className="group-header">
                <OperatorToggleSwitch onToggle={handleOperatorChange} />
            </div>
            <div className="group-content">
                {expression.conditions.map((cond, index) =>
                    cond.conditions ? (
                        <SegmentExpressionBuilder
                            key={index}
                            expression={cond}
                            updateExpression={updateExpression}
                            path={[...path, index]}
                            onRemove={() => removeExpression(index)}
                            showAlertPopup={showAlertPopup}
                        />
                    ) : (
                        <SegmentCondition
                            gameId={gameId}
                            key={index}
                            condition={cond}
                            updateCondition={updateExpression}
                            path={[...path, index]}
                            onRemove={() => removeExpression(index)}
                        />
                    )
                )}
            </div>
            <div className="expression-button-group">
                <button type="button" className="add-button" onClick={handleAddCondition}>
                    <FontAwesomeIcon icon={faCirclePlus} className="add-icon"/>Condition
                </button>
                <button type="button" className="add-button" onClick={handleAddGroup}>
                    <FontAwesomeIcon icon={faCirclePlus} className="add-icon"/>Group
                </button>
                {path.length > 0 && (
                    <button type="button" className="delete-button" onClick={onRemove}>
                        <FontAwesomeIcon icon={faTrashCan} className="delete-icon"/>
                    </button>
                )}
            </div>
        </div>
    );
};