import React, {useEffect} from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { GameRegistrationForm } from './GameRegistrationForm';
import "../../../common/GeoPlayModal.css";

Modal.setAppElement('#root');

export const RegisterGameModal = ({ isOpen, onRequestClose }) => {

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Register a New Game"
            className="modal"
            overlayClassName="modal-overlay"
        >
            <button className="close-button" onClick={onRequestClose}><FontAwesomeIcon icon={faXmark} /></button>
            <h2>Register a New Game</h2>
            <GameRegistrationForm onRequestClose={onRequestClose} />
        </Modal>
    );
};