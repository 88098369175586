import React, { useCallback, useState, useEffect } from 'react';
import Modal from "react-modal";
import { UserAuth } from "../../auth/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { ModalButtons } from "../../common/modal/ModalButtons";
import { PlayerAttributeForm } from "./PlayerAttributeForm";
import axios from "axios";
import { config } from "../../../config/Config";
import "../../common/GeoPlayModal.css";

export const NewPlayerAttribute = ({ gameId, isOpen, onClose, onCreate }) => {
    const { user } = UserAuth();
    const [name, setName] = useState('');
    const [dataType, setDataType] = useState('java.lang.String');
    const [defaultValue, setDefaultValue] = useState('');
    const [error, setError] = useState(null);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newAttribute = { name, dataType, defaultValue };

        try {
            const url = `${config.serverBaseUrl}/v1/web/player-attribute`;
            await axios({
                method: 'post', url: url, data: newAttribute,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`,
                    'X-Web-Game-Id': gameId,
                },
            });
            setShowSuccessPopup(true);
            onCreate(newAttribute);
        } catch (error) {
            setError(error.message);
        }
    };

    const handleClosePopup = useCallback(() => {
        setShowSuccessPopup(false);
        onClose();
    }, [onClose]);

    useEffect(() => {
        if (showSuccessPopup) {
            const timer = setTimeout(() => {
                handleClosePopup();
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [showSuccessPopup, handleClosePopup]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Create New Attribute"
            className="modal"
            overlayClassName="modal-overlay"
        >
            <button className="close-button" onClick={onClose}><FontAwesomeIcon icon={faXmark} /></button>
            <h2>Create New Attribute</h2>
            <form className="modal-form" onSubmit={handleSubmit}>
                <PlayerAttributeForm
                    gameId={gameId}
                    name={name}
                    setName={setName}
                    dataType={dataType}
                    setDataType={setDataType}
                    defaultValue={defaultValue}
                    setDefaultValue={setDefaultValue}
                />
                {error && <div className="error-message">{error}</div>}
                <ModalButtons onSubmit={handleSubmit} onCancel={onClose} />
            </form>
            {showSuccessPopup && (
                <div className="success-popup">
                    <p>Request Submitted Successfully!</p>
                    <button onClick={handleClosePopup}>Ok</button>
                </div>
            )}
        </Modal>
    );
};