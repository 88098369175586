import React from 'react';
import "./DashboardFooter.css"

export const DashboardFooter = () => {
    return (
        <footer className="dashboard-footer">
            <p>&copy; 2024 Liquidnitro Games, Hyderabad, India, All rights reserved.</p>
            <div className="footer-links">
                <a href="/about">About</a> |
                <a href="/help-center">Help Center</a> |
                <a href="/privacy-policy">Privacy Policy</a> |
                <a href="/terms-of-service">Terms of Service</a>
            </div>
        </footer>
    );
};