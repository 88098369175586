import React from 'react';
import {Route, Routes} from "react-router-dom";
import {Portfolio} from "./portfolio/Portfolio";
import {Metrics} from "./metrics/Metrics";

export const UserDashboardContent = () => {

    return (
        <Routes>
            <Route path="portfolio" element={<Portfolio />} />
            <Route path="metrics" element={<Metrics />} />
        </Routes>
    );
};