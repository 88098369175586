import React from 'react';

export const InfoSection = () => {
    return (
        <section className="info-section">
            <p>
                Info...
            </p>
        </section>
    );
};