import {createContext, useContext, useEffect, useState} from 'react';
import {
    auth,
    createUserWithEmailAndPassword,
    googleAuthProvider,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut
} from './Firebase';

const AuthContext = createContext();

export const UserAuth = () => {
    return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const signUp = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password);
    };

    const signInWithEmailPassword = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    };

    const signInWithGoogle = async () => {
        try {
            return await signInWithPopup(auth, googleAuthProvider);
        } catch (error) {
            console.error("Error during Google sign-in:", error);
            throw error;
        }
    };

    const logOut = () => {
        return signOut(auth);
    };

    useEffect(() => {
        return onAuthStateChanged(auth, (user) => {
            setUser(user);
        });
    }, []);

    const value = {
        user,
        signInWithEmailPassword,
        signInWithGoogle,
        signUp,
        logOut
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};
