import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import GoogleButton from "react-google-button";
import { UserAuth } from './AuthContext';
import { config } from "../../config/Config";
import axios from "axios";
import './SignIn.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";

export const SignIn = () => {
    const { user, signInWithEmailPassword, signInWithGoogle } = UserAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [isAuthenticating, setIsAuthenticating] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            await signInWithEmailPassword(email, password);
        } catch (e) {
            setError(e.message);
            console.log(e.message);
        }
    };

    const handleGoogleSignIn = async () => {
        setError('');
        setLoading(true);
        setIsAuthenticating(true);
        try {
            await signInWithGoogle();
        } catch (error) {
            setError(error.message || "An error occurred during Google sign-in.");
            setIsAuthenticating(false);
        } finally {
            setLoading(false);
        }
    };

    const createNewUser = async (userCredential) => {
        if (!userCredential || !userCredential.user) {
            console.error('Invalid user credential');
            return null;
        }

        const url = `${config.serverBaseUrl}/v1/web/user`;
        const body = {
            userId: userCredential.user.email,
            userName: userCredential.user.displayName,
            password: 'password'
        };

        try {
            const response = await axios.post(url, body, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${userCredential.user.accessToken}`,
                },
            });

            if (response.status === 200 || response.status === 201) {
                console.log('User registered successfully:', response.data);
                return response.data.user || { email: userCredential.user.email };
            } else {
                console.error(`Unexpected HTTP status: ${response.status}`);
                return null;
            }
        } catch (error) {
            console.error('Failed to register user:', error);
            if (error.response && error.response.status === 409) {
                console.log('User already exists, proceeding with sign-in');
                return { email: userCredential.user.email };
            }
            return null;
        }
    };

    const userAlreadyExists = async (email, accessToken) => {
        if (!email || !accessToken) {
            console.error('Invalid email or access token');
            return null;
        }

        const url = `${config.serverBaseUrl}/v1/web/user?userId=${email}`;
        try {
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });
            if (response.status === 200) {
                return response.data.user;
            } else {
                console.error(`Unexpected HTTP status: ${response.status}`);
                return null;
            }
        } catch (error) {
            if (error.response) {
                console.log('Error fetching user:', error.response);
                if (error.response.status === 404) {
                    // User not found
                    return null;
                }
            } else if (error.request) {
                console.log('No response received:', error.request);
            } else {
                console.log('Error setting up request:', error.message);
            }
            console.error('Error details:', error.toJSON());
            return null;
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    useEffect(() => {
        const checkUserAndNavigate = async () => {
            if (user && user.email && user.accessToken) {
                setIsAuthenticating(true);
                try {
                    let existingUser = await userAlreadyExists(user.email, user.accessToken);
                    if (!existingUser) {
                        const newUser = await createNewUser({ user });
                        existingUser = newUser;
                    }

                    if (existingUser) {
                        if (existingUser.orgId) {
                            navigate('/user-dashboard/portfolio');
                        } else {
                            navigate('/register');
                        }
                    } else {
                        setError("Failed to create or retrieve user information.");
                    }
                } catch (error) {
                    console.error("Error checking user status:", error);
                    setError("An error occurred while processing your sign in.");
                } finally {
                    setIsAuthenticating(false);
                }
            } else {
                setIsAuthenticating(false);
            }
        };

        checkUserAndNavigate();
    }, [user, navigate]);

    return (
        <div className="login-container">
            <h1 className="logo">GeoPlay</h1>
            <div className="login-box">
                <h2>Log in to your account</h2>
                <div className="google-button-custom-wrapper">
                    {isAuthenticating ? (
                        <div className="google-button-loading">Authenticating using Google Sign-In...</div>
                    ) : user?.displayName ? (
                        <button className="google-login-continue">
                            <div className="google-login-continue-text-container">
                                <img src={user.photoURL} alt="Google" className="profile-pic"/>
                                <div>
                                    <p className="google-login-continue-text">Continue as {user.displayName}</p>
                                    <span>{user.email}</span>
                                </div>
                            </div>
                        </button>
                    ) : (
                        <GoogleButton onClick={handleGoogleSignIn} disabled={loading}/>
                    )}
                </div>
                <div className="login-or-div">
                    <div className="login-or">or</div>
                </div>

                <form onSubmit={handleSubmit}>
                    <label htmlFor="email"><b>Email</b><span className="asterisk">*</span></label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="e.g. test@example.com"
                        required
                    />
                    <label htmlFor="password"><b>Password</b><span className="asterisk">*</span></label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Enter your password"
                        required
                    />
                    <div className="login-box-button">
                        <button type="submit" className="continue-btn" disabled={loading}>
                            <FontAwesomeIcon icon={faRightToBracket} className="login-icon"/>
                            Continue
                        </button>
                    </div>
                </form>
                <p className="signup-link">
                    Don't have an account? <Link to="/signup"><b>Sign Up</b></Link>
                </p>
                <p className="help-links">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    Having Trouble Logging in? <a href="#"><b>Click here</b></a> to set up a new password
                    <br/>
                    <br/>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    If you need help <a href="#"><b>Contact Support</b></a>
                </p>
            </div>
            {error && <div className="error">{error}</div>}
        </div>
    );
};