import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from './AuthContext';
import { RequestHandler } from "../common/RequestHandler";
import GoogleButton from "react-google-button";
import './SignIn.css';

export const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { handleRequest } = RequestHandler();
    const {user, signUp, signInWithGoogle} = UserAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSignUp = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const user = await signUp(email, password);
            if (user) {
                await createNewUser({ name: user.displayName, fullName: user.displayName, email: user.email, uid: user.uid });
            }
        } catch (error) {
            console.error('Google sign-in error', error);
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            const user = await signInWithGoogle();
            if (user) {
                await createNewUser({ userId: user.email, userName: user.displayName });
            }
        } catch (error) {
            console.error('Google sign-in error', error);
        }
    };

    const createNewUser = async (user) => {
        setLoading(true);
        const body = {
            userId: user.email,
            userName: user.displayName,
            password: 'password'
        };

        await handleRequest('post', 'user-mgmt', null, body, (data) => {
            console.log('User registered successfully:', data);
        });
    };

    useEffect(() => {
        if (user) {
            navigate('/user-dashboard/portfolio');
        }
    }, [user, navigate]);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    return (
        <div className="login-container">
            <h1 className="logo">GeoPlay</h1>
            <div className="login-box">
                <h2>Sign up for a free account</h2>

                <div className="google-button-custom-wrapper">
                    {user?.displayName ? (
                        <button className="google-login">
                            <img src="google-icon.png" alt="Google"/>
                            Continue as {user.displayName}
                            <span>{user.email}</span>
                        </button>
                    ) : (
                        <GoogleButton onClick={handleGoogleSignIn}/>
                    )}
                </div>
                <div className="login-or-div">
                    <div className="login-or">or</div>
                </div>

                <form onSubmit={handleSignUp}>
                    <label htmlFor="email"><b>Email</b><span className="asterisk">*</span></label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="e.g. test@example.com"
                        required
                    />
                    <label htmlFor="password"><b>Password</b><span className="asterisk">*</span></label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Enter your password"
                        required
                    />
                    <div className="login-box-button">
                        <button type="submit" className="continue-btn">Sign Up</button>
                    </div>
                    </form>
                <p className="signup-link">
                    Already have an account? <Link to="/signin"><b>Sign In</b></Link>
                </p>
            </div>
        </div>
    );
};