import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import * as configModule from "../../../config/Config";
import { UserAuth } from '../../auth/AuthContext';
import { RemoteConfigForm } from './RemoteConfigForm';

export const UpdateRemoteConfig = () => {
    const { user } = UserAuth();
    const { gameId, regionId, id } = useParams();
    const [initialFormData, setInitialFormData] = useState(null);

    useEffect(() => {
        const fetchRemoteConfig = async () => {
            try {
                const url = `${configModule.config.serverBaseUrl}/v1/web/remote-config/${id}`;
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`,
                    'X-Web-Game-Id': gameId,
                    'X-Web-Region-Id': regionId
                };
                const response = await axios.get(url, { headers });
                const remoteConfig = response.data.config;
                setInitialFormData({
                    name: remoteConfig.name,
                    featureId: remoteConfig.featureId,
                    minClientVersion: remoteConfig.minClientVersion,
                    maxClientVersion: remoteConfig.maxClientVersion,
                    segmentId: remoteConfig.segmentId,
                    iosEnabled: remoteConfig.platform.iosEnabled,
                    androidEnabled: remoteConfig.platform.androidEnabled,
                    content: remoteConfig.content,
                    contentFormat: remoteConfig.contentFormat,
                    contentLocation: remoteConfig.contentLocation,
                    startDate: remoteConfig.startDate,
                    endDate: remoteConfig.endDate,
                    active: remoteConfig.active
                });
            } catch (error) {
                console.error('Error fetching Remote Config:', error);
            }
        };

        fetchRemoteConfig().then(r => console.log("Successfully fetched Remote Config data"));
    }, [gameId, regionId, id, user.accessToken]);

    if (!initialFormData) {
        return <div>Loading...</div>;
    }

    return <RemoteConfigForm isUpdate={true} initialFormData={initialFormData} />;
};