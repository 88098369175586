import React, { memo } from 'react';

const textualInsightConfigs = {
    'insights/retention_text.csv': 'Retention Insights',
};

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("TextualInsights error:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong in TextualInsights.</h1>;
        }

        return this.props.children;
    }
}

const InsightRenderer = memo(({ fileName, data }) => {
    const displayName = textualInsightConfigs[fileName] || fileName.replace('.csv', '').replace(/_/g, ' ');

    if (!data || data.length === 0) {
        return (
            <div className="textual-insight warning">
                <h3>{displayName}</h3>
                <p>No data available</p>
            </div>
        );
    }

    return (
        <div className="textual-insight">
            <h3>{displayName}</h3>
            {data.map((row, index) => (
                <div key={index} className="qa-pair">
                    <p><strong>{row.Question}</strong></p>
                    <p>{row.Insights}</p>
                </div>
            ))}
        </div>
    );
});

export const TextualInsights = memo(({ textualData }) => {
    if (!textualData || typeof textualData !== 'object') {
        console.error('Invalid textualData prop:', textualData);
        return <div>Error: Invalid textual data</div>;
    }

    if (Object.keys(textualData).length === 0) {
        return <div>No textual data available</div>;
    }

    return (
        <ErrorBoundary>
            <div className="textual-insights-container">
                {Object.entries(textualData).map(([fileName, data]) => (
                    <InsightRenderer key={fileName} fileName={fileName} data={data} />
                ))}
            </div>
        </ErrorBoundary>
    );
});