import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUsers, faSitemap, faGamepad} from '@fortawesome/free-solid-svg-icons';
import "../common/SideMenuBar.css"

export const SettingsDashboardSidebar = ({ gameId }) => {
    const location = useLocation();
    const [selected, setSelected] = useState(location.pathname);

    useEffect(() => {
        setSelected(location.pathname);
    }, [location.pathname]);

    return (
        <div className="dashboard-sidebar">
            <ul className="nav-links">
                <li className={selected === `/settings/user-mgmt` ? 'selected' : ''}>
                    <Link to={`/settings/user-mgmt`} onClick={() => setSelected(`/settings/user-mgmt`)}>
                        <FontAwesomeIcon icon={faUsers} className="dashboard-sidebar-li-icon"/>
                        <span className="icon-text"/>User Management
                    </Link>
                </li>
                <li className={selected === `/settings/game-mgmt` ? 'selected' : ''}>
                    <Link to={`/settings/game-mgmt`} onClick={() => setSelected(`/settings/game-mgmt`)}>
                        <FontAwesomeIcon icon={faGamepad} className="dashboard-sidebar-li-icon"/>
                        <span className="icon-text"/>Game Management
                    </Link>
                </li>
                <li className={selected === `/settings/org-mgmt` ? 'selected' : ''}>
                    <Link to={`/settings/org-mgmt`} onClick={() => setSelected(`/settings/org-mgmt`)}>
                        <FontAwesomeIcon icon={faSitemap} className="dashboard-sidebar-li-icon"/>
                        <span className="icon-text"/>Org Management
                    </Link>
                </li>
            </ul>
        </div>
    );
};