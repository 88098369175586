import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import axios from 'axios';
import { UserAuth } from "../../auth/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ModalButtons } from "../../common/modal/ModalButtons";
import { PlayerAttributeForm } from "./PlayerAttributeForm";
import { getDataTypeValue } from './DataTypes';
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { config } from "../../../config/Config";
import "../../common/GeoPlayModal.css";

export const UpdatePlayerAttribute = ({ gameId, isOpen, onClose, attribute, onUpdate }) => {
    const { user } = UserAuth();
    const [name, setName] = useState('');
    const [dataType, setDataType] = useState('');
    const [defaultValue, setDefaultValue] = useState('');
    const [error, setError] = useState(null);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);

    useEffect(() => {
        if (attribute) {
            setName(attribute.name || '');
            setDataType(getDataTypeValue(attribute.dataType) || ''); // Ensure dataType is set correctly
            setDefaultValue(attribute.defaultValue || '');
        }
    }, [attribute]);

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const url = `${config.serverBaseUrl}/v1/web/player-attribute/${attribute.id}`;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.accessToken}`,
                'X-Web-Game-Id': gameId
            };
            const updatedAttribute = { id: attribute.id, name, dataType, defaultValue };
            await axios.put(url, updatedAttribute, { headers });
            onUpdate(updatedAttribute);
            setShowSuccessPopup(true);
        } catch (error) {
            setError(error.message);
        }
    };

    const handleClosePopup = useCallback(() => {
        setShowSuccessPopup(false);
        onClose();
    }, [onClose]);

    useEffect(() => {
        if (showSuccessPopup) {
            const timer = setTimeout(() => {
                handleClosePopup();
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [showSuccessPopup, handleClosePopup]);

    if (!attribute) {
        return null;
    }

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel="Update Player Attribute"
            className="modal"
            overlayClassName="modal-overlay"
        >
            <button className="close-button" onClick={onClose}><FontAwesomeIcon icon={faXmark} /></button>
            <h2>Update Player Attribute</h2>
            <form className="modal-form" onSubmit={handleUpdate}>
                <PlayerAttributeForm
                    gameId={gameId}
                    name={name}
                    setName={setName}
                    dataType={dataType}
                    setDataType={setDataType}
                    defaultValue={defaultValue}
                    setDefaultValue={setDefaultValue}
                />
                {error && <div className="error-message">{error}</div>}
                <ModalButtons onSubmit={handleUpdate} onCancel={onClose} />
            </form>
            {showSuccessPopup && (
                <div className="success-popup">
                    <p>Attribute Updated Successfully!</p>
                    <button onClick={handleClosePopup}>Ok</button>
                </div>
            )}
        </Modal>
    );
};

UpdatePlayerAttribute.propTypes = {
    gameId: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    attribute: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
        dataType: PropTypes.string,
        defaultValue: PropTypes.string
    }),
    onUpdate: PropTypes.func.isRequired
};