import React from 'react';

export const Hero = () => {
    return (
        <main className="hero">
            <h1>Amplify your game's reach with precision and insight</h1>
            <p>
                Amplify your game's reach with precision and insight by leveraging data-driven strategies and targeted marketing.
                Harness the power of analytics to understand your audience, optimize engagement, and maximize growth.
                Stay ahead of the competition with tailored campaigns that resonate and drive results, ensuring your game's success in a
                competitive market
            </p>
        </main>
    );
};