import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faXmark } from "@fortawesome/free-solid-svg-icons";
import { AlertPopup } from "../../common/popups/AlertPopup";
import { INITIAL_EXPRESSION } from "./common/SegmentExpressionConstants";
import { SegmentExpressionBuilder } from "./common/SegmentExpressionBuilder";
import { conditionToString, updateExpression, parseExpressionString } from "./common/SegmentExpressionUtils";
import { config } from "../../../config/Config";
import { UserAuth } from "../../auth/AuthContext";
import axios from "axios";
import './common/SegmentExpression.css';
import './common/AmendPlayerSegment.css';
import '../../common/Content.css';

export const UpdatePlayerSegment = () => {
    const { user } = UserAuth();
    const { gameId, regionId, id } = useParams();
    const [segmentName, setSegmentName] = useState('');
    const [alert, setAlert] = useState(null);
    const [expression, setExpression] = useState(INITIAL_EXPRESSION);
    const [expressionString, setExpressionString] = useState('');
    const [isConfirmation, setIsConfirmation] = useState(false);
    const navigate = useNavigate();

    const fetchSegmentData = useCallback(async () => {
        try {
            const url = `${config.serverBaseUrl}/v1/web/player-segment/${id}`;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.accessToken}`,
                'X-Web-Game-Id': gameId,
                'X-Web-Region-Id': 'US'
            };
            const response = await axios.get(url, { headers });
            const segment = response.data.segment || {};
            setSegmentName(segment.segmentName);
            setExpressionString(segment.segmentExpression);

            if (segment.segmentExpression) {
                const parsedExpression = parseExpressionString(segment.segmentExpression);
                setExpression(parsedExpression);
            }
        } catch (error) {
            console.error('Error fetching segment data:', error);
            showAlertPopup('Failed to fetch segment data. Please try again.');
        }
    }, [gameId, id, user.accessToken]);

    useEffect(() => {
        fetchSegmentData().then(() => console.log("Successfully fetched Player Segment data"));
    }, [fetchSegmentData]);

    useEffect(() => {
        if (expression && expression.conditions) {
            setExpressionString(conditionToString(expression));
        } else {
            setExpressionString('');
        }
    }, [expression]);

    const showAlertPopup = (alert, confirmation = false) => {
        setAlert(alert);
        setIsConfirmation(confirmation);
    };

    const closeAlertPopup = () => {
        setAlert(null);
    };

    const handleExpressionUpdate = (path, updater) => {
        setExpression(prevExpression => updateExpression(prevExpression, path, updater));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.put(`${config.serverBaseUrl}/v1/web/player-segment/${id}`, {
                segmentName: segmentName,
                segmentExpression: expressionString,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`,
                    'X-Web-Game-Id': gameId,
                    'X-Web-Region-Id': 'US'
                }
            });
            if (response.status === 200) {
                showAlertPopup('Segment updated successfully!');
                navigate(`/game/${gameId}/${regionId}/player-segment`);
            } else {
                showAlertPopup('Failed to update segment. Please try again.');
            }
        } catch (error) {
            console.error('Error updating segment:', error);
            showAlertPopup('Failed to update segment. Please try again.');
        }
    };

    const handleCancel = () => {
        showAlertPopup("Are you sure you want to cancel? All unsaved changes will be lost.", true);
    };

    const handleConfirmCancel = () => {
        closeAlertPopup();
        navigate(`/game/${gameId}/${regionId}/player-segment`);
    };

    return (
        <div className="content-element">
            <div className="content-element-header">
                <h1>Update Player Segment</h1>
            </div>

            <div className="amend-player-segment-section">
                <form className="amend-player-segment-form" onSubmit={handleSubmit}>
                    <div>
                        <label>Game<span className="asterisk">*</span></label>{gameId}
                    </div>
                    <div>
                        <label>Segment Name<span className="asterisk">*</span></label>
                        <input
                            type="text"
                            id="segmentName"
                            value={segmentName}
                            onChange={(e) => setSegmentName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="segment-condition">
                        <label>Segment Condition<span className="asterisk">*</span></label>
                        <h6>{expressionString}</h6>
                        <SegmentExpressionBuilder
                            gameId={gameId}
                            expression={expression}
                            updateExpression={handleExpressionUpdate}
                            path={[]}
                            showAlertPopup={showAlertPopup}
                        />
                    </div>
                    <div className="content-action-buttons">
                        <button type="submit" className="submit-button">
                            <FontAwesomeIcon icon={faFloppyDisk} className="submit-icon"/>Update
                        </button>
                        <button type="button" className="cancel-button" onClick={handleCancel}>
                            <FontAwesomeIcon icon={faXmark} className='cancel-icon'/>Cancel
                        </button>
                    </div>
                </form>
                {alert && (
                    <AlertPopup
                        message={alert}
                        onClose={closeAlertPopup}
                        onConfirm={isConfirmation ? handleConfirmCancel : undefined}
                        isConfirmation={isConfirmation}
                    />
                )}
            </div>
        </div>
    );
};