import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { InfoIcon } from "../common/info-icon/InfoIcon";
import { UserAuth } from "../auth/AuthContext";
import { config } from "../../config/Config";
import axios from "axios";
import "./OrgRegistration.css";
import {faCheck, faFloppyDisk, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const OrgRegistration = () => {
    const { user } = UserAuth();
    const navigate = useNavigate();
    const [useExistingOrg, setUseExistingOrg] = useState(null);
    const [existingOrgs, setExistingOrgs] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [requestSent, setRequestSent] = useState(false);

    const [formData, setFormData] = useState({
        orgName: '',
        orgId: '',
        orgOwner: '',
        orgEmail: ''
    });

    const fetchExistingOrganizations = useCallback(async () => {
        try {
            const url = `${config.serverBaseUrl}/v1/web/org-mgmt`;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.accessToken}`,
            };
            const response = await axios.get(url, { headers });
            const orgs = response.data.orgs || [];
            setExistingOrgs(orgs);
        } catch (error) {
            console.error('Failed to fetch existing organizations:', error);
        }
    }, [user.accessToken]);

    useEffect(() => {
        if (useExistingOrg) {
            fetchExistingOrganizations().then(() => console.log('Existing organizations fetched successfully'));
        }
    }, [useExistingOrg, fetchExistingOrganizations]);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleUseExistingOrgChange = (value) => {
        setUseExistingOrg(value);
        setSelectedOrg('');
        setFormData({
            orgName: '',
            orgId: '',
            orgOwner: '',
            orgEmail: ''
        });
        setShowConfirmation(false);
    };

    const handleOrgSelect = async (e) => {
        const selectedOrgId = e.target.value;
        setSelectedOrg(selectedOrgId);

        if (selectedOrgId) {
            try {
                const url = `${config.serverBaseUrl}/v1/web/org-mgmt/${selectedOrgId}`;
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`,
                };
                const response = await axios.get(url, { headers });
                const org = response.data.org;
                console.log('Organization details:', org.orgId);
                setFormData({
                    orgName: org.orgName,
                    orgId: org.orgId,
                    orgOwner: org.orgOwner,
                    orgEmail: org.orgEmail
                });
                setShowConfirmation(true);
            } catch (error) {
                console.error('Failed to fetch organization details:', error);
            }
        } else {
            setShowConfirmation(false);
        }
    };

    const registerOrganization = async (formData) => {
        const formBody = {
            orgId: formData.orgId,
            orgName: formData.orgName,
            orgOwner: formData.orgOwner,
            orgEmail: formData.orgEmail
        };

        try {
            const url = `${config.serverBaseUrl}/v1/web/org-mgmt`;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.accessToken}`,
            };
            const response = await axios.post(url, formBody, {headers});
            console.log('Organization has been registered successfully!');
            return response.data.org;
        } catch (error) {
            console.error('Failed to register Organization:', error);
            throw error;
        }
    };

    // Send request to join an existing organization FIXME - approval process should be implemented
    const requestToJoinOrg = async (userId, orgId) => {
        try {
            const url = `${config.serverBaseUrl}/v1/web/user/assign-to-org?orgId=${orgId}`;
            const response = await axios({
                method: "post",
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`,
                }
            });
            console.log('Join request sent successfully:', response.data);
            setRequestSent(true);
            setTimeout(() => navigate('/user-dashboard/portfolio'), 2000);
        } catch (error) {
            console.error('Failed to send join request:', error);
            throw error;
        }
    };

    // Assign user to the newly created organization
    const assignToOrg = async (userId, orgId) => {
        try {
            const url = `${config.serverBaseUrl}/v1/web/user/assign-to-org?orgId=${orgId}`;
            const response = await axios({
                method: "post",
                url: url,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`,
                }
            });
            console.log('User has been assigned to an Org successfully:', response.data);
            setRequestSent(true);
            setTimeout(() => navigate('/user-dashboard/portfolio'), 2000);
        } catch (error) {
            console.error('Failed to assign to an Org:', error);
            throw error;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (useExistingOrg) {
                await requestToJoinOrg(user.email, selectedOrg);
            } else {
                const orgData = await registerOrganization(formData);
                if (orgData && orgData.orgId) {
                    await assignToOrg(user.email, orgData.orgId);
                    console.log('User assigned to the newly created organization');
                    navigate('/user-dashboard/portfolio');
                } else {
                    console.error('Organization registration failed ->', orgData);
                }
            }
        } catch (error) {
            console.error('An error occurred during the form submission:', error);
        }
    };

    const handleCancel = () => {
        setUseExistingOrg(null);
        setSelectedOrg('');
        setFormData({
            orgName: '',
            orgId: '',
            orgOwner: '',
            orgEmail: ''
        });
        setShowConfirmation(false);
    };

    if (requestSent) {
        return <div>Request sent to join the organization. Redirecting to dashboard...</div>;
    }

    return (
        <div className="register-container">
            <h1 className="logo">GeoPlay</h1>
            <div className="register-box">
                <h2>Register your organization</h2>

                {useExistingOrg === null && (
                    <div className="initial-choice">
                        <p>Would like to join an existing organization?</p>
                        <button className="yes-btn" type="button" onClick={() => handleUseExistingOrgChange(true)}>
                            <FontAwesomeIcon icon={faCheck} className="popup-button-icon"/>Yes
                        </button>
                        <button className="no-btn" type="button" onClick={() => handleUseExistingOrgChange(false)}>
                            <FontAwesomeIcon icon={faXmark} className="popup-button-icon"/>No
                        </button>
                    </div>
                )}

                {useExistingOrg !== null && (
                    <form onSubmit={handleSubmit}>
                        {useExistingOrg ? (
                            <div className="org-selection">
                                <label htmlFor="existingOrg">Select Existing Organization</label>
                                <select id="existingOrg" value={selectedOrg} onChange={handleOrgSelect}>
                                    <option value="">Select an organization</option>
                                    {existingOrgs.map(org => (
                                        <option key={org.orgId} value={org.orgId}>{org.orgName}</option>
                                    ))}
                                </select>

                                {showConfirmation ? (
                                    <div className="confirmation-section">
                                        <h3>Organization Details:</h3>
                                        <p>Name: {formData.orgName}</p>
                                        <p>ID: {formData.orgId}</p>
                                        <div className="button-group">
                                            <button className="confirm-btn" type="submit"><FontAwesomeIcon icon={faFloppyDisk} className="submit-icon" />Send Join Request</button>
                                            <button className="cancel-btn" type="button" onClick={handleCancel}><FontAwesomeIcon icon={faXmark} className='cancel-icon' />Cancel</button>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="button-group">
                                            <button className="cancel-btn" type="button" onClick={handleCancel}><FontAwesomeIcon icon={faXmark} className='cancel-icon' />Go Back</button>
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : (
                            <>
                                <label htmlFor="name">Name<span className="asterisk">*</span></label>
                                <input type="text" id="orgName" placeholder="Organization" value={formData.orgName}
                                       onChange={handleChange} />

                                <label htmlFor="logo">Logo <small>(Max 2MB. JPEG, PNG)</small> </label>
                                <button type="button" className="upload-btn">Upload</button>

                                <label htmlFor="identifier">Identifier<span className="asterisk">*</span></label>
                                <input type="text" id="orgId" placeholder="Organization Unique Identifier"
                                       value={formData.orgId} onChange={handleChange} />

                                <label htmlFor="orgOwner">
                                    Org Owner <InfoIcon tip="The owner has the ability to oversee both the organization and its games, as well as the capability to enlist additional users to manage the games."/>
                                    <span className="asterisk">*</span>
                                </label>
                                <input type="text" id="orgOwner" placeholder="Org Owner"
                                       value={formData.orgOwner} onChange={handleChange} />

                                <label htmlFor="identifier">Email<span className="asterisk">*</span></label>
                                <input type="email" id="orgEmail" placeholder="org@example.com"
                                       value={formData.orgEmail} onChange={handleChange} />

                                <div className="button-group">
                                    <button type="submit" className="register-btn"><FontAwesomeIcon icon={faFloppyDisk} className="submit-icon" />Register</button>
                                    <button type="button" onClick={handleCancel} className="cancel-btn"><FontAwesomeIcon icon={faXmark} className='cancel-icon' />Cancel</button>
                                </div>
                            </>
                        )}
                    </form>
                )}
            </div>
        </div>
    );
};