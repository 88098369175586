import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { NewPlayerAttribute } from "./NewPlayerAttribute";
import { UpdatePlayerAttribute } from "./UpdatePlayerAttribute";
import { ActionsMenuRenderer } from "../../common/actions-menu/ActionsMenuRenderer";
import { AlertPopup } from "../../common/popups/AlertPopup";
import { UserAuth } from "../../auth/AuthContext";
import { AgGridReact } from "ag-grid-react";
import { config } from "../../../config/Config";
import axios from "axios";
import './PlayerAttributes.css';
import '../../common/Content.css';

export const PlayerAttribute = () => {
    const { user } = UserAuth();
    const { gameId } = useParams();
    const [isCreateNewAttributeModalOpen, setIsCreateNewAttributeModalOpen] = useState(false);
    const [isUpdateAttributeModalOpen, setIsUpdateAttributeModalOpen] = useState(false);
    const [attributes, setAttributes] = useState([]);
    const [rowData, setRowData] = useState([]);
    const [error, setError] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [attributeIdToDelete, setAttributeIdToDelete] = useState(null);
    const [attributeToUpdate, setAttributeToUpdate] = useState(null);

    const handleUpdate = useCallback((id, data) => {
        setAttributeToUpdate({ id, ...data });
        setIsUpdateAttributeModalOpen(true);
    }, []);

    const handleDelete = (id) => {
        setAttributeIdToDelete(id);
        setShowAlert(true);
    };

    const actionsRenderer = (params) => {
        const { id, ...restData } = params.data;
        if (!id) {
            return null;
        }

        const actions = [
            {
                icon: faPen,
                label: 'Update',
                handler: () => {
                    handleUpdate(id, restData);
                }
            },
            {
                icon: faTrash,
                label: 'Delete',
                handler: () => handleDelete(id)
            }
        ];
        return <ActionsMenuRenderer actions={actions} />;
    };

    const onConfirmDelete = async () => {
        if (attributeIdToDelete) {
            try {
                const url = `${config.serverBaseUrl}/v1/web/player-attribute/${attributeIdToDelete}`;
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`,
                    'X-Web-Game-Id': gameId
                };
                await axios.delete(url, { headers });
                await fetchAttributes();
            } catch (error) {
                console.error('Error deleting attribute:', error);
            } finally {
                setShowAlert(false);
                setAttributeIdToDelete(null);
            }
        }
    };

    const onCloseAlert = () => {
        setShowAlert(false);
        setAttributeIdToDelete(null);
    };

    const [columnDefs] = useState([
        {
            headerName: "Actions",
            cellRenderer: actionsRenderer,
            maxWidth: 100
        },
        { field: "name" },
        { field: "dataType" },
        { field: "defaultValue" },
        { field: "lastModifiedDate" },
        { field: "lastModifiedBy" }
    ]);

    const defaultColDef = useMemo(() => {
        return {
            filter: "agTextColumnFilter",
            floatingFilter: true,
        };
    }, []);

    const fetchAttributes = useCallback(async () => {
        try {
            const url = `${config.serverBaseUrl}/v1/web/player-attribute`;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.accessToken}`,
                'X-Web-Game-Id': gameId,
            };
            const response = await axios.get(url, { headers });
            const attributes = response.data.attributes || [];
            setAttributes(attributes);
            setRowData(attributes);
            setError('');
        } catch (error) {
            console.error('Error fetching attributes:', error);
            setError("Failed to fetch Player Attributes with error " + error.message + ", please contact support");
            setRowData([]);
        }
    }, [gameId, user.accessToken]);

    useEffect(() => {
        fetchAttributes().then(() => {
            console.log("Player Attributes have been fetched successfully!");
        });
    }, [fetchAttributes]);

    const handleCreateNewAttributeClick = () => {
        setIsCreateNewAttributeModalOpen(true);
    };

    const closeNewAttributeModal = () => {
        setIsCreateNewAttributeModalOpen(false);
    };

    const closeUpdateAttributeModal = () => {
        setIsUpdateAttributeModalOpen(false);
        setAttributeToUpdate(null);
    };

    const handleCreateNewAttribute = (newAttribute) => {
        const updatedAttributes = [...attributes, newAttribute];
        setAttributes(updatedAttributes);
        setRowData(updatedAttributes);
    };

    const handleUpdateAttribute = (updatedAttribute) => {
        const updatedAttributes = attributes.map(attr =>
            attr.id === updatedAttribute.id ? updatedAttribute : attr
        );
        setAttributes(updatedAttributes);
        setRowData(updatedAttributes);
    };

    return (
        <div className="content-element">
            <div className="content-element-header">
                <h1>Player Attributes</h1>
                <button onClick={handleCreateNewAttributeClick}>
                    <FontAwesomeIcon icon={faCirclePlus} />
                    <span className="button-icon-text" />Create New Attribute
                </button>
                <NewPlayerAttribute gameId={gameId}
                                    isOpen={isCreateNewAttributeModalOpen}
                                    onClose={closeNewAttributeModal}
                                    onCreate={handleCreateNewAttribute}
                />
                {attributeToUpdate && (
                    <UpdatePlayerAttribute
                        gameId={gameId}
                        isOpen={isUpdateAttributeModalOpen}
                        onClose={closeUpdateAttributeModal}
                        attribute={attributeToUpdate}
                        onUpdate={handleUpdateAttribute}
                    />
                )}
            </div>
            <div className={"ag-theme-quartz"} style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    key={rowData.length} // Force re-render when rowData changes
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowSelection="multiple"
                    suppressRowClickSelection={true}
                    pagination={true}
                    paginationPageSize={10}
                />
            </div>
            {error && <div className="error-message">{error}</div>}
            {showAlert && (
                <AlertPopup
                    message="Are you sure you want to delete this attribute?"
                    onClose={onCloseAlert}
                    onConfirm={onConfirmDelete}
                    isConfirmation={true}
                />
            )}
        </div>
    );
};