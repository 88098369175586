import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faXmark } from "@fortawesome/free-solid-svg-icons";
import "./ModalButtons.css";

export const ModalButtons = ({ onSubmit, onCancel }) => {
    return (
        <div className="modal-buttons">
            <button type="submit" onClick={onSubmit} className="submit-button">
                <FontAwesomeIcon icon={faFloppyDisk} className="submit-icon" />Submit
            </button>
            <button type="button" onClick={onCancel} className="cancel-button">
                <FontAwesomeIcon icon={faXmark} className='cancel-icon' />Cancel
            </button>
        </div>
    );
};