import React from 'react';
import './AlertPopup.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faXmark} from "@fortawesome/free-solid-svg-icons";

export const AlertPopup = ({ message, onClose, onConfirm, isConfirmation }) => {
    return (
        <div className="alert-overlay">
            <div className="custom-error-popup">
                <p>{message}</p>
                {isConfirmation ? (
                    <div className="alert-popup-buttons">
                        <button className="yes-button" onClick={onConfirm}><FontAwesomeIcon icon={faCheck} className="popup-button-icon"/>Yes</button>
                        <button className="no-button" onClick={onClose}><FontAwesomeIcon icon={faXmark} className="popup-button-icon"/>No</button>
                    </div>
                ) : (
                    <button onClick={onClose}>Close</button>
                )}
            </div>
        </div>
    );
};