import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCog,
    faCaretDown,
    faAddressCard,
    faArrowRightFromBracket,
    faBullhorn,
    faSitemap,
    faGamepad
} from '@fortawesome/free-solid-svg-icons';
import { CountryCodes } from "./common/country-selector/CountryCodes";
import { UserAuth } from './auth/AuthContext';
import { useSelector } from "react-redux";
import './DashboardHeader.css';

export const DashboardHeader = () => {
    const { user, logOut } = UserAuth();
    const { gameId, regionId } = useParams();
    const userGames = useSelector(state => state.userGames);
    const [selectedGameId, setSelectedGameId] = useState(gameId);
    const [selectedRegionId, setSelectedRegionId] = useState(regionId);
    const [previousGameId, setPreviousGameId] = useState(selectedGameId);
    const [previousRegionId, setPreviousRegionId] = useState(selectedRegionId);
    const [regions, setRegions] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const [activeMenu, setActiveMenu] = useState(null);
    const profileMenuRef = useRef(null);
    const settingsMenuRef = useRef(null);

    const toggleMenuVisibility = () => setActiveMenu(activeMenu === 'profile' ? null : 'profile');
    const toggleSettingsVisibility = () => setActiveMenu(activeMenu === 'settings' ? null : 'settings');

    const getCountryName = (code) => {
        const country = CountryCodes.find(country => country.code === code);
        return country ? country.name : code;
    };

    const handleClickOutside = (event) => {
        if (
            (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) &&
            (settingsMenuRef.current && !settingsMenuRef.current.contains(event.target))
        ) {
            setActiveMenu(null);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (selectedGameId) {
            const selectedGameRegions = userGames
                .filter(game => game.gameId === selectedGameId)
                .map(game => game.regionId);
            setRegions(selectedGameRegions);
        }
    }, [selectedGameId, userGames]);

    useEffect(() => {
        if (selectedGameId && selectedRegionId &&
            (selectedGameId !== previousGameId || selectedRegionId !== previousRegionId)) {
            navigate(`/game/${selectedGameId}/${selectedRegionId}/overview`);
            setPreviousGameId(selectedGameId);
            setPreviousRegionId(selectedRegionId);
        }
    }, [selectedGameId, selectedRegionId, navigate, previousGameId, previousRegionId]);

    const handleLogOut = async () => {
        console.log("Logout button clicked in ProfileMenu");
        try {
            await logOut();
            console.log("Log out successful");
            navigate("/");
        } catch (error) {
            console.error('Log out error', error);
        }
    };

    const uniqueGameIds = [...new Set(userGames.map(game => game.gameId))];

    const isGamePath = location.pathname.startsWith('/game/');

    return (
        <header className="game-dashboard-header">
            <div className="left-container">
                <Link to="/user-dashboard/portfolio"><p>GeoPlay</p></Link>
            </div>
            {isGamePath && (
                <div className="center-container">
                    <div className="selected-game">
                        <select id="gameId" value={selectedGameId} onChange={(e) => setSelectedGameId(e.target.value)} required>
                            <option value="" disabled>Select a game</option>
                            {uniqueGameIds.map(gameId => (
                                <option key={gameId} value={gameId}>{gameId}</option>
                            ))}
                        </select>
                    </div>
                    <div className="selected-region">
                        <select id="regionId" value={selectedRegionId} onChange={(e) => setSelectedRegionId(e.target.value)} required>
                            <option value="" disabled>Select a region</option>
                            {regions.map(regionId => (
                                <option key={regionId} value={regionId}>{getCountryName(regionId)}</option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
            <div className="right-container">
                <div className="alerts">
                    <button className="settings-button" onClick={() => navigate('/alerts')}>
                        <FontAwesomeIcon icon={faBullhorn} className="alerts-icon"/>
                    </button>
                </div>
                <div className="settings" ref={settingsMenuRef}>
                    <button className="settings-button" onClick={toggleSettingsVisibility}>
                        <FontAwesomeIcon icon={faCog} className="settings-icon"/>
                    </button>
                    {activeMenu === 'settings' && (
                        <div className="menu-content">
                            <div className="menu-items">
                                <Link to="/settings/user-mgmt">
                                    <FontAwesomeIcon className="menu-icon" icon={faAddressCard}/>
                                    User Management
                                </Link>
                                <Link to="/settings/game-mgmt">
                                    <FontAwesomeIcon className="menu-icon" icon={faGamepad}/>
                                    Game Management
                                </Link>
                                <Link to="/settings/org-mgmt">
                                    <FontAwesomeIcon className="menu-icon" icon={faSitemap}/>
                                    Organization Management
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
                <div className="user-profile" ref={profileMenuRef}>
                    {user ? (
                        <>
                            <button className="profile-button" onClick={toggleMenuVisibility}>
                                <img src={user.photoURL} alt="Profile" className="profile-pic"/>
                                <FontAwesomeIcon icon={faCaretDown} className="profile-drop-down-icon"/>
                            </button>
                            {activeMenu === 'profile' && (
                                <div className="menu-content profile-menu">
                                    <div className="profile-menu-user-welcome">
                                        <h4>Welcome {user.displayName}!</h4>
                                        <h6>{user.email}</h6>
                                    </div>
                                    <div className="menu-items">
                                        <Link to="/profile">
                                            <FontAwesomeIcon className="menu-icon" icon={faAddressCard}/>
                                            Profile
                                        </Link>
                                        <button onClick={handleLogOut}>
                                            <FontAwesomeIcon className="menu-icon" icon={faArrowRightFromBracket}/>
                                            Logout
                                        </button>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <Link to="/signin">Log In</Link>
                    )}
                </div>
            </div>
        </header>
    );
};