import React, { useState } from 'react';
import './InfoIcon.css';

export const InfoIcon = ({ tip }) => {
    const [showTip, setShowTip] = useState(false);

    return (
        <span className="info-icon-container">
            <i className="info-icon"
               onMouseEnter={() => setShowTip(true)}
               onMouseLeave={() => setShowTip(false)}>
                i
            </i>
            {showTip && <span className="tooltip" style={{display: 'block'}}>{tip}</span>}
        </span>
    );
};