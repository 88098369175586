import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { OPERATOR_OPTIONS } from './SegmentExpressionConstants';
import { UserAuth } from "../../../auth/AuthContext";
import { config } from "../../../../config/Config";
import PropTypes from 'prop-types';
import axios from 'axios';

export const SegmentCondition = ({ gameId, condition, updateCondition, path, onRemove }) => {
    const { user } = UserAuth();
    const [localValue, setLocalValue] = useState(String(condition.value));
    const [attributes, setAttributes] = useState([]);

    const handleFieldChange = (e) => {
        updateCondition(path, cond => ({ ...cond, field: e.target.value }));
    };

    const handleOperatorChange = (e) => {
        updateCondition(path, cond => ({ ...cond, operator: e.target.value }));
    };

    const handleValueChange = (e) => {
        setLocalValue(e.target.value);
    };

    const handleValueBlur = () => {
        updateCondition(path, cond => ({ ...cond, value: localValue }));
    };

    useEffect(() => {
        setLocalValue(String(condition.value));
    }, [condition.value]);

    useEffect(() => {
        const fetchFieldOptions = async () => {
            try {
                const response = await axios.get(`${config.serverBaseUrl}/v1/web/player-attribute`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${user.accessToken}`,
                        'X-Web-Game-Id': gameId
                    }
                });
                const attributes = response.data.attributes || [];
                setAttributes(attributes);
            } catch (error) {
                console.error('Error fetching field options:', error);
            }
        };

        fetchFieldOptions().then(r => console.log("Successfully fetched Player Attributes!"));
    }, [gameId, user.accessToken]);

    return (
        <div className="condition">
            <select value={condition.field} onChange={handleFieldChange}>
                <option value="">Select Attribute</option>
                {attributes.map(option => (
                    <option key={option.name} value={option.name}>{option.name}</option>
                ))}
            </select>
            <select value={condition.operator} onChange={handleOperatorChange}>
                <option value="">Select Operator</option>
                {OPERATOR_OPTIONS.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </select>
            <input
                type="text"
                placeholder="Enter Value"
                value={localValue}
                onChange={handleValueChange}
                onBlur={handleValueBlur}
            />
            <button type="button" className="delete-button" onClick={onRemove}>
                <FontAwesomeIcon icon={faTrashCan} className="delete-icon"/>
            </button>
        </div>
    );
};

SegmentCondition.propTypes = {
    gameId: PropTypes.string.isRequired,
    condition: PropTypes.shape({
        field: PropTypes.string,
        operator: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }).isRequired,
    updateCondition: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired
};