import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from "../auth/AuthContext";
import '../../App.css';

export const Header = () => {
    const { user, logOut } = UserAuth();
    const navigate = useNavigate();

    const handleSignOut = async () => {
        try {
            await logOut()
        } catch (error) {
            console.log(error)
        }
    }

    const handleLoginClick = () => {
        navigate('/signin');
    }

    return (
        <header className="header">
            <div className="logo">GeoPlay</div>
            <nav>
                <ul>
                    <li>Resources</li>
                    <li>Company</li>
                    <li>About Us</li>
                </ul>
            </nav>
            <div className="auth-buttons">
                {user?.displayName ? (
                    <button onClick={handleSignOut}>Logout</button>
                ) : (
                    <button className="login-btn" onClick={handleLoginClick}>Log In</button>
                )}
            </div>
        </header>
    );
};