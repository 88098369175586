import { useState, useEffect, useCallback } from 'react';
import { config } from "../../../config/Config";
import { UserAuth } from "../../auth/AuthContext";

export const InsightsData = (gameId, fileNames) => {
    const { user } = UserAuth();
    const [responseData, setResponseData] = useState({
        data: {},
        successCount: 0,
        failureCount: 0,
        totalFiles: 0,
        failedFiles: []
    });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchData = useCallback(async () => {
        if (!user?.accessToken || !gameId) {
            setError("Missing authentication or game ID");
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        setError(null);
        try {
            const url = `${config.serverBaseUrl}/v1/web/gp-storage/batch-download`;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.accessToken}`,
                'X-Web-Game-Id': gameId
            };
            const body = JSON.stringify({
                bucketName: 'lng-geo-play',
                fileNames: fileNames
            });

            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: body
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setResponseData(data);

        } catch (error) {
            console.error("Error fetching data: ", error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    }, [user?.accessToken, gameId, fileNames]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {
        chartData: {} || {},
        textualData: {} || {},
        metricsData: responseData?.data || {},
        downloadStats: {
            successCount: responseData?.successCount || 0,
            failureCount: responseData?.failureCount || 0,
            totalFiles: responseData?.totalFiles || 0,
            failedFiles: responseData?.failedFiles || []
        },
        isLoading,
        error,
        refetch: fetchData
    };
};