import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AlertCircle } from 'lucide-react';

const chartConfigs = {
    'insights/game_revenues_plot.csv': { xAxis: 'Date', yAxis: 'Revenue', color: 'rgb(130, 202, 157)', name: 'Revenues' },
};

export const InsightCharts = ({ chartData }) => {
    const renderChart = (fileName, data) => {
        const config = chartConfigs[fileName] || { xAxis: 'datetime', yAxis: 'value', color: '#8884d8', name: fileName };

        if (!data || data.length === 0) {
            return (
                <div className="no-data-container">
                    <AlertCircle className="no-data-icon" />
                    <span className="no-data-text">No data available</span>
                </div>
            );
        }

        return (
            <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={config.xAxis} />
                    <YAxis dataKey={config.yAxis} />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey={config.yAxis} stroke={config.color} />
                </LineChart>
            </ResponsiveContainer>
        );
    };

    return (
        <div className="multi-chart-container">
            {Object.entries(chartData).map(([fileName, data]) => (
                <div key={fileName} className="chart-container">
                    <h3>{chartConfigs[fileName]?.name || fileName.replace('.csv', '').replace(/_/g, ' ')}</h3>
                    {renderChart(fileName, data)}
                </div>
            ))}
        </div>
    );
};