import React, { useState, useEffect } from 'react';
import { CountryCodes } from "./CountryCodes";
import "./CountrySelector.css";

export const CountrySelector = ({ incomingCountryCodes, selectedCountries, setSelectedCountries }) => {
    const [availableCountries, setAvailableCountries] = useState(CountryCodes);
    const [selectedAvailable, setSelectedAvailable] = useState([]);
    const [selectedSelected, setSelectedSelected] = useState([]);

    useEffect(() => {
        if (incomingCountryCodes && incomingCountryCodes.length > 0) {
            const filteredCountries = CountryCodes.filter(country => incomingCountryCodes.includes(country.code));
            setAvailableCountries(filteredCountries);
        } else {
            setAvailableCountries(CountryCodes);
        }
    }, [incomingCountryCodes]);

    const handleAvailableSelect = (event) => {
        setSelectedAvailable(Array.from(event.target.selectedOptions, option => option.value));
    };

    const handleSelectedSelect = (event) => {
        setSelectedSelected(Array.from(event.target.selectedOptions, option => option.value));
    };

    const moveToSelected = () => {
        const countriesToMove = availableCountries.filter(country => selectedAvailable.includes(country.code));
        setSelectedCountries([...selectedCountries, ...countriesToMove]);
        setAvailableCountries(availableCountries.filter(country => !selectedAvailable.includes(country.code)));
        setSelectedAvailable([]);
    };

    const moveToAvailable = () => {
        const countriesToMove = selectedCountries.filter(country => selectedSelected.includes(country.code));
        setAvailableCountries([...availableCountries, ...countriesToMove].sort((a, b) => a.name.localeCompare(b.name)));
        setSelectedCountries(selectedCountries.filter(country => !selectedSelected.includes(country.code)));
        setSelectedSelected([]);
    };

    const moveAllToSelected = () => {
        setSelectedCountries([...selectedCountries, ...availableCountries]);
        setAvailableCountries([]);
    };

    const moveAllToAvailable = () => {
        setAvailableCountries([...availableCountries, ...selectedCountries].sort((a, b) => a.name.localeCompare(b.name)));
        setSelectedCountries([]);
    };

    return (
        <div className="country-selector">
            <div className="country-list-container">
                <label>Available Regions<span className="asterisk">*</span></label>
                <div className="country-list">
                    <select multiple onChange={handleAvailableSelect}>
                        {availableCountries.map(country => (
                            <option key={country.code} value={country.code}>
                                {country.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="move-buttons">
                <button type="button" className="move-to-selected-btn" onClick={moveToSelected} disabled={selectedAvailable.length === 0}>&gt;</button>
                <button className="move-all-to-selected-btn" type="button" onClick={moveAllToSelected} disabled={availableCountries.length === 0}>&gt;&gt;</button>
                <button className="move-to-available-btn" type="button" onClick={moveToAvailable} disabled={selectedSelected.length === 0}>&lt;</button>
                <button className="move-all-to-available-btn" type="button" onClick={moveAllToAvailable} disabled={selectedCountries.length === 0}>&lt;&lt;</button>
            </div>
            <div className="country-list-container">
                <label>Selected Region</label>
                <div className="country-list">
                    <select multiple onChange={handleSelectedSelect}>
                        {selectedCountries.map(country => (
                            <option key={country.code} value={country.code}>
                                {country.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};