import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { Button, Menu, MenuItem } from '@mui/material';
import './ActionsMenuRenderer.css';

export const ActionsMenuRenderer = ({ actions }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAction = (action) => {
        action.handler();
        handleClose();
    };

    return (
        <div className="actions-cell">
            <Button onClick={handleClick} className="more-options-btn">
                <FontAwesomeIcon icon={faEllipsisVertical} className="more-options-icon" />
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    '& .MuiPaper-root': {
                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                        borderRadius: 2,
                        overflow: 'visible',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 8,
                            height: 8,
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
            >
                {actions.map((action, index) => (
                    <MenuItem key={index} onClick={() => handleAction(action)}>
                        <FontAwesomeIcon icon={action.icon} className="action-menu-icon" />
                        <p className="action-menu-item-text">{action.label}</p>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};