import React, { useMemo } from 'react';
import { useParams } from "react-router-dom";
import { InsightCharts } from "./InsightCharts";
import { MetricInsights } from "./MetricInsights";
import { TextualInsights } from "./TextualInsights";
import { InsightsData } from './InsightsData';
import { Loader2 } from "lucide-react";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "../../common/utils/CommonUtil.css";
import './AIInsights.css';

const EmptyState = ({ message }) => (
    <div className="empty-state">
        <p className="empty-state-message">{message}</p>
    </div>
);

export const AIInsights = () => {
    const { gameId, regionId } = useParams();
    const insightFiles = useMemo(() => [
        'insights/acquisition_insights.json',
        'insights/retention_insights.json',
        'insights/engagement_insights.json',
        'insights/monetization_insights.json',
    ], []);

    const {
        chartData = {},
        textualData = {},
        metricsData = {},
        isLoading,
        error,
        downloadStats
    } = InsightsData(gameId, insightFiles);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-64">
                <Loader2 className="h-8 w-8 animate-spin" />
                <span className="ml-2">Loading insights...</span>
            </div>
        );
    }

    if (error) {
        return (
            <div className="error-state">
                <p className="error-message">Failed to load insights: {error}</p>
            </div>
        );
    }

    const hasAnyData = Object.keys(chartData || {}).length > 0 ||
        Object.keys(metricsData || {}).length > 0 ||
        Object.keys(textualData || {}).length > 0;

    return (
        <section className="insights-element">
            <div className="insights-element-header">
                <h2>{gameId} - {regionId} - Insights</h2>
            </div>

            {!hasAnyData ? (
                <EmptyState message="No insights data is currently available for this game and region." />
            ) : (
                <div className="insights-content space-y-8">
                    {/* Charts Section */}
                    <div className="charts-section">
                        <h3 className="text-xl font-semibold mb-4">Performance Charts</h3>
                        {Object.keys(chartData || {}).length > 0 ? (
                            <InsightCharts
                                chartData={chartData}
                                metricsData={metricsData}
                            />
                        ) : (
                            <EmptyState message="No performance charts available." />
                        )}
                    </div>

                    {/* Metrics Section */}
                    <div className="metrics-section">
                        <h3 className="text-xl font-semibold mb-4">Analysis Insights</h3>
                        {Object.keys(metricsData || {}).length > 0 ? (
                            <MetricInsights
                                metricsData={metricsData}
                                downloadStats={downloadStats}
                            />
                        ) : (
                            <EmptyState message="No analysis insights available." />
                        )}
                    </div>

                    {/* Textual Insights Section */}
                    {/*<div className="textual-section">*/}
                    {/*    <h3 className="text-xl font-semibold mb-4">Detailed Insights</h3>*/}
                    {/*    {Object.keys(textualData || {}).length > 0 ? (*/}
                    {/*        <TextualInsights textualData={textualData} />*/}
                    {/*    ) : (*/}
                    {/*        <EmptyState message="No detailed insights available." />*/}
                    {/*    )}*/}
                    {/*</div>*/}
                </div>
            )}
        </section>
    );
};
