import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { faSquareCheck, faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import { ActionsMenuRenderer } from "../../common/actions-menu/ActionsMenuRenderer";
import { UserAuth } from "../../auth/AuthContext";
import { config } from "../../../config/Config";
import axios from "axios";
import '../../common/Content.css'

export const UserGameAccessMgmt = () => {
    const { user } = UserAuth();
    const [rowData, setRowData] = useState([]);
    const [error, setError] = useState('');

    const handleApprove = async (id) => {
        try {
            const url = `${config.serverBaseUrl}/v1/web/user-game-access/${id}/APPROVED`;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.accessToken}`,
            };
            await axios.post(url, {}, { headers });
            await fetchUserGameAccessRequests();
        } catch (error) {
            console.error('Error approving access request:', error);
            setError("Failed to approve access request with error " + error.message + ", please contact support");
        }
    };

    const handleReject = async (id) => {
        try {
            const url = `${config.serverBaseUrl}/v1/web/user-game-access/${id}/REJECTED`;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.accessToken}`,
            };
            await axios.post(url, {}, { headers });
            await fetchUserGameAccessRequests();
        } catch (error) {
            console.error('Error rejecting access request:', error);
            setError("Failed to reject access request with error " + error.message + ", please contact support");
        }
    };

    const actionsRenderer = (params) => {
        const actions = [
            {
                icon: faSquareCheck,
                label: 'Approve',
                handler: () => handleApprove(params.data.requestId)
            },
            {
                icon: faSquareXmark,
                label: 'Reject',
                handler: () => handleReject(params.data.requestId)
            }
        ];
        return <ActionsMenuRenderer actions={actions} />;
    };

    const [columnDefs] = useState([
        {
            headerName: "Actions",
            cellRenderer: actionsRenderer,
            minWidth: 100
        },
        { field: "requestId" },
        { field: "userId" },
        { field: "userName"},
        { field: "gameId" },
        { field: "role" },
        { field: "accessStatus" },
        { field: "accessRequestedDate" }
    ]);

    const defaultColDef = useMemo(() => {
        return {
            filter: "agTextColumnFilter",
            floatingFilter: true,
        };
    }, []);

    const fetchUserGameAccessRequests = useCallback(async () => {
        try {
            const url = `${config.serverBaseUrl}/v1/web/user-game-access?approvingUserId=${user.email}`;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.accessToken}`,
            };
            const response = await axios.get(url, { headers });
            const accessRequests = response.data.accessRequests || [];
            setRowData(accessRequests);
            setError('');
        } catch (error) {
            console.error('Error fetching User Game Access Requests:', error);
            setError("Failed to fetch User Game Access Requests with error " + error.message + ", please contact support");
            setRowData([]);
        }
    }, [user.accessToken, user.email]);

    useEffect(() => {
        fetchUserGameAccessRequests().then(() => console.log("User Game Access Requests have been fetched successfully!"));
    }, [fetchUserGameAccessRequests]);

    return (
        <div className="content-element">
            <div className="content-element-header">
                <h1>User Management</h1>
            </div>
            <div className="ag-grid-container ag-theme-quartz"
                 style={{
                     height: 'calc(100vh - 200px)',
                     width: '100%',
                     position: 'relative',
                     zIndex: 0
                 }}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowSelection="multiple"
                    suppressRowClickSelection={true}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector={[10, 25, 50]}
                    suppressMenuHide={true}
                    alwaysMultiSort={true}
                    enableCellTextSelection={true}
                    suppressContextMenu={true}
                    suppressMovableColumns={true}
                />
            </div>
            {error && <div className="error-message">{error}</div>}
        </div>
    );
};
