import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faRectangleList,
    faMagnifyingGlassChart,
    faBarsStaggered,
    faPeopleGroup,
    faGears,
    faFlask
} from '@fortawesome/free-solid-svg-icons';
import "../common/SideMenuBar.css"

export const GameDashboardSidebar = ({ gameId, regionId }) => {
    const location = useLocation();
    const [selected, setSelected] = useState(location.pathname);

    useEffect(() => {
        setSelected(location.pathname);
    }, [location.pathname]);

    return (
        <div className="dashboard-sidebar">
            <ul className="nav-links">
                <li className={selected.startsWith(`/game/${gameId}/${regionId}/overview`) ? 'selected' : ''}>
                    <Link to={`/game/${gameId}/${regionId}/overview`}
                          onClick={() => setSelected(`/game/${gameId}/${regionId}/overview`)}>
                        <FontAwesomeIcon icon={faRectangleList} className="dashboard-sidebar-li-icon"/>
                        <span className="icon-text">Overview</span>
                    </Link>
                </li>
                <li className={selected.startsWith(`/game/${gameId}/${regionId}/insights`) ? 'selected' : ''}>
                    <Link to={`/game/${gameId}/${regionId}/insights`}
                          onClick={() => setSelected(`/game/${gameId}/${regionId}/insights`)}>
                        <FontAwesomeIcon icon={faMagnifyingGlassChart} className="dashboard-sidebar-li-icon"/>
                        <span className="icon-text">AI Insights</span>
                    </Link>
                </li>
                <li className={selected.startsWith(`/game/${gameId}/${regionId}/player-attribute`) ? 'selected' : ''}>
                    <Link to={`/game/${gameId}/${regionId}/player-attribute`}
                          onClick={() => setSelected(`/game/${gameId}/${regionId}/player-attribute`)}>
                        <FontAwesomeIcon icon={faBarsStaggered} className="dashboard-sidebar-li-icon"/>
                        <span className="icon-text">Player Attributes</span>
                    </Link>
                </li>
                <li className={selected.startsWith(`/game/${gameId}/${regionId}/player-segment`) ? 'selected' : ''}>
                    <Link to={`/game/${gameId}/${regionId}/player-segment`}
                          onClick={() => setSelected(`/game/${gameId}/${regionId}/player-segment`)}>
                        <FontAwesomeIcon icon={faPeopleGroup} className="dashboard-sidebar-li-icon"/>
                        <span className="icon-text">Segments</span>
                    </Link>
                </li>
                <li className={selected.startsWith(`/game/${gameId}/${regionId}/remote-config`) ? 'selected' : ''}>
                    <Link to={`/game/${gameId}/${regionId}/remote-config`}
                          onClick={() => setSelected(`/game/${gameId}/${regionId}/remote-config`)}>
                        <FontAwesomeIcon icon={faGears} className="dashboard-sidebar-li-icon"/>
                        <span className="icon-text">Remote Config</span>
                    </Link>
                </li>
                <li className={selected.startsWith(`/game/${gameId}/${regionId}/ab-test`) ? 'selected' : ''}>
                    <Link to={`/game/${gameId}/${regionId}/ab-test`}
                          onClick={() => setSelected(`/game/${gameId}/${regionId}/ab-test`)}>
                        <FontAwesomeIcon icon={faFlask} className="dashboard-sidebar-li-icon"/>
                        <span className="icon-text">A/B Test</span>
                    </Link>
                </li>
            </ul>
        </div>
    );
};