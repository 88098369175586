import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ActionsMenuRenderer } from "../../common/actions-menu/ActionsMenuRenderer";
import { AlertPopup } from "../../common/popups/AlertPopup";
import { UserAuth } from "../../auth/AuthContext";
import { config } from "../../../config/Config";
import axios from "axios";
import '../../common/Content.css';

export const PlayerSegment = () => {
    const { user } = UserAuth();
    const { gameId, regionId } = useParams();
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [error, setError] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [segmentToDelete, setSegmentToDelete] = useState(null);

    const handleUpdate = (id) => {
        navigate(`/game/${gameId}/${regionId}/player-segment/${id}/update-player-segment`);
    };

    const handleDelete = (id) => {
        setSegmentToDelete(id);
        setShowAlert(true);
    };

    const onConfirmDelete = async () => {
        if (segmentToDelete) {
            try {
                const url = `${config.serverBaseUrl}/v1/web/player-segment/${segmentToDelete}`;
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.accessToken}`,
                    'X-Web-Game-Id': gameId
                };
                await axios.delete(url, { headers });
                await fetchSegments();
            } catch (error) {
                console.error('Error deleting segment:', error);
            } finally {
                setShowAlert(false);
                setSegmentToDelete(null);
            }
        }
    };

    const onCloseAlert = () => {
        setShowAlert(false);
        setSegmentToDelete(null);
    };

    const actionsRenderer = (params) => {
        if (params.data.id === "ALL_PLAYERS") {
            return null;
        }

        const actions = [
            {
                icon: faPen,
                label: 'Update',
                handler: () => handleUpdate(params.data.id)
            },
            {
                icon: faTrash,
                label: 'Delete',
                handler: () => handleDelete(params.data.id)
            }
        ];
        return <ActionsMenuRenderer actions={actions} />;
    };

    const [columnDefs] = useState([
        {
            headerName: "Actions",
            cellRenderer: actionsRenderer,
            maxWidth: 100
        },
        { field: "segmentName" },
        { field: "segmentExpression" },
        { field: "lastModifiedDate" },
        { field: "lastModifiedBy" }
    ]);

    const defaultColDef = useMemo(() => {
        return {
            filter: "agTextColumnFilter",
            floatingFilter: true,
        };
    }, []);

    const fetchSegments = useCallback(async () => {
        try {
            const url = `${config.serverBaseUrl}/v1/web/player-segment`;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.accessToken}`,
                'X-Web-Game-Id': gameId,
                'X-Web-Region-Id': 'US'
            };
            const response = await axios.get(url, { headers });
            const segments = response.data.segments || [];
            setRowData(segments);
            setError('');
        } catch (error) {
            console.error('Error fetching Player Segments:', error);
            setError("Failed to fetch Player Segments with error " + error.message + ", please contact support");
            setRowData([]);
        }
    }, [gameId, user.accessToken]);

    useEffect(() => {
        fetchSegments().then(() => console.log("Player Segments have been fetched successfully!"));
    }, [fetchSegments]);

    function handleCreateNewSegment() {
        navigate(`/game/${gameId}/${regionId}/player-segment/new-player-segment`);
    }

    return (
        <div className="content-element">
            <div className="content-element-header">
                <h1>Segments</h1>
                <button onClick={handleCreateNewSegment}>
                    <FontAwesomeIcon icon={faCirclePlus} />
                    <span className="button-icon-text" />Create New Segment
                </button>
            </div>
            <div className={"ag-theme-quartz"} style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowSelection="multiple"
                    suppressRowClickSelection={true}
                    pagination={true}
                    paginationPageSize={10}
                    paginationPageSizeSelector={[10, 25, 50]}
                />
            </div>
            {error && <div className="error-message">{error}</div>}
            {showAlert && (
                <AlertPopup
                    message="Are you sure you want to delete this segment?"
                    onClose={onCloseAlert}
                    onConfirm={onConfirmDelete}
                    isConfirmation={true}
                />
            )}
        </div>
    );
};