import React, {useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserAuth } from '../../auth/AuthContext';
import { AlertPopup } from "../../common/popups/AlertPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useRemoteConfigData } from './UseRemoteConfigData';
import { CreateGameFeatureModal } from "../game-feature/CreateGameFeatureModal";
import { CreateClientVersionModal } from "../client-version/CreateClientVersionModal";
import {BasicDetails, Schedule, Target, Activate, ContentFormat, ContentLocation} from '../common/FormFieldUtil';
import PropTypes from "prop-types";
import { config } from "../../../config/Config";
import axios from 'axios';
import './AmendRemoteConfig.css';
import '../../common/Content.css';
import {
    isValidJson,
    handleToggle,
    handleCancel,
    showAlertPopup,
    closeAlertPopup,
    validateSchedule,
    validateFileType,
    handleEndDateChange,
    handlePlatformChange,
    handleStartDateChange,
    handlePlatformCheckboxChange
} from "../common/FormUtil";

export const RemoteConfigForm = ({ isUpdate = false, initialFormData = {} }) => {
    const { user } = UserAuth();
    const { gameId, regionId, id } = useParams();
    const [alert, setAlert] = useState(null);
    const [isConfirmation] = useState(false);
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [setFileError] = useState(null);
    const [setJsonError] = useState(null);
    const [features, setFeatures] = useState([]);
    const [versions, setVersions] = useState([]);
    const [segments, setSegments] = useState([]);
    const [isFeatureModalOpen, setIsFeatureModalOpen] = useState(false);
    const [isClientVersionModalOpen, setIsClientVersionModalOpen] = useState(false);
    const [isActive, setIsActive] = useState(initialFormData.active || false);
    const allowedFileTypes = ['application/json', 'text/csv', 'application/xml', 'application/vnd.ms-excel'];
    const [formData, setFormData] = useState({
        ...initialFormData,
        platforms: initialFormData.platforms || [],
        startDate: initialFormData.startDate ? new Date(initialFormData.startDate) : null,
        endDate: initialFormData.endDate ? new Date(initialFormData.endDate) : null
    });

    useRemoteConfigData(user, gameId, setFeatures, setVersions, setSegments);

    useEffect(() => {
        if (initialFormData.iosEnabled && initialFormData.androidEnabled) {
            setFormData(prevState => ({
                ...prevState,
                platforms: [...new Set([...prevState.platforms, 'all'])]
            }));
        }
    }, [initialFormData.iosEnabled, initialFormData.androidEnabled]);

    const handleConfirmCancel = () => {
        closeAlertPopup(setAlert);
        navigate(`/game/${gameId}/${regionId}/remote-config`);
    };

    const handleCreateNewFeature = (newFeature) => {
        setFeatures([...features, newFeature]);
    };

    const handleCreateNewClientVersion = (newClientVersion) => {
        setVersions([...versions, newClientVersion]);
    };

    const handleInputChange = (e) => {
        const { name, value, type, files, checked } = e.target;

        if (type === 'checkbox') {
            if (name === 'platforms') {
                handlePlatformChange(e, formData, setFormData);
            } else {
                setFormData(prevState => ({
                    ...prevState,
                    [name]: checked
                }));
            }
        } else if (type === 'file') {
            const file = files[0];
            if (validateFileType(file, allowedFileTypes, setFileError)) {
                setFormData(prevState => ({
                    ...prevState,
                    content: file
                }));
            }
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }

        if (name === 'contentFormat') {
            setFormData(prevState => ({
                ...prevState,
                contentLocation: value === 'JSON' ? 'INLINE' : 'S3/CDN'
            }));
        }

        if (name === 'iosEnabled' || name === 'androidEnabled') {
            handlePlatformCheckboxChange(name, checked, formData, setFormData);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateSchedule(formData, setError)) {
            return;
        }

        if (formData.contentFormat === 'JSON' && !isValidJson(formData.content)) {
            setJsonError("Invalid JSON format. Please enter a valid JSON.");
            return;
        }
        try {
            const url = isUpdate
                ? `${config.serverBaseUrl}/v1/web/remote-config/${id}`
                : `${config.serverBaseUrl}/v1/web/remote-config`;
            const method = isUpdate ? 'put' : 'post';
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.accessToken}`,
                'X-Web-Game-Id': gameId,
                'X-Web-Region-Id': regionId
            };
            await axios[method](url, formData, { headers });
            navigate(`/game/${gameId}/${regionId}/remote-config`);
        } catch (error) {
            console.error(`Error ${isUpdate ? 'updating' : 'creating'} Remote Config:`, error);
            setError(`Failed to ${isUpdate ? 'update' : 'create'} Remote Config. Please try again.`);
        }
    };

    return (
        <div className="content-element">
            <div className="content-element-header">
                <h1>{isUpdate ? 'Update' : 'Create New'} Remote Config</h1>
            </div>
            <div className="remote-config-container">
                <form className="remote-config-form" onSubmit={handleSubmit}>
                    <div className="remote-config-content">
                        <div className="content-row">
                            <div className="remote-config-content-section name-section">
                                <h3>Basic Details</h3>
                                <BasicDetails formData={formData} handleInputChange={handleInputChange} />
                            </div>
                            <div className="remote-config-content-section schedule-section">
                                <h3>Schedule</h3>
                                <Schedule formData={formData} handleStartDateChange={(date) => handleStartDateChange(date, setFormData)} handleEndDateChange={(date) => handleEndDateChange(date, setFormData)} />
                            </div>
                        </div>
                        <div className="content-row">
                            <div className="remote-config-content-section targeting-section">
                                <h3>Target</h3>
                                <Target formData={formData} features={features} versions={versions} segments={segments} handleInputChange={handleInputChange} setIsFeatureModalOpen={setIsFeatureModalOpen} setIsClientVersionModalOpen={setIsClientVersionModalOpen} />
                            </div>
                            <div className="remote-config-content-section actiivate-section">
                                <h3>Activate</h3>
                                <Activate isActive={isActive} handleToggle={() => handleToggle(isActive, setIsActive, setFormData)} />
                            </div>
                        </div>
                        <div className="content-row">
                            <div className="remote-config-content-section content-section">
                                <h3>Content</h3>
                                <div className="form-group">
                                    <ContentFormat formData={formData} handleInputChange={handleInputChange}
                                                   isDisabled={false}/>
                                </div>
                                <div className="form-group">
                                    <ContentLocation formData={formData} handleInputChange={handleInputChange}
                                                     isDisabled={true}/>
                                </div>
                                {formData.contentFormat === 'JSON' ? (
                                    <div className="form-group">
                                        <label htmlFor="content">Content<span className="asterisk">*</span></label>
                                        <textarea id="content" name="content" placeholder="Enter JSON content"
                                                  value={formData.content} onChange={handleInputChange} required/>
                                    </div>
                                ) : (
                                    <div className="form-group">
                                        <label htmlFor="content">Content<span className="asterisk">*</span></label>
                                        <input type="file" id="content" name="content" onChange={handleInputChange}
                                               required/>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    <div className="content-action-buttons">
                        <button type="submit" className="submit-button">
                            <FontAwesomeIcon icon={faFloppyDisk} className="submit-icon"/>
                            {isUpdate ? 'Update' : 'Submit'}
                        </button>
                        <button type="button" className="cancel-button"
                                onClick={() => handleCancel(showAlertPopup)}>
                            <FontAwesomeIcon icon={faXmark} className='cancel-icon'/>Cancel
                        </button>
                    </div>
                </form>
                {alert && (
                    <AlertPopup
                        message={alert}
                        onClose={() => closeAlertPopup(setAlert)}
                        onConfirm={isConfirmation ? handleConfirmCancel : undefined}
                        isConfirmation={isConfirmation}
                    />
                )}
                <CreateGameFeatureModal
                    isOpen={isFeatureModalOpen}
                    onClose={() => setIsFeatureModalOpen(false)}
                    onCreate={handleCreateNewFeature}
                />
                <CreateClientVersionModal
                    isOpen={isClientVersionModalOpen}
                    onClose={() => setIsClientVersionModalOpen(false)}
                    onCreate={handleCreateNewClientVersion}
                />
            </div>
        </div>
    );
};

RemoteConfigForm.propTypes = {
    isUpdate: PropTypes.bool,
    initialFormData: PropTypes.shape({
        active: PropTypes.bool,
        platforms: PropTypes.arrayOf(PropTypes.string),
        startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
        contentFormat: PropTypes.string,
        contentLocation: PropTypes.string,
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
        iosEnabled: PropTypes.bool,
        androidEnabled: PropTypes.bool,
    })
};